import { useEffect, useState } from 'react';
import './App.scss';
import MainRouter from './MainRouter';
//import { requestPermission } from './app/services/firebase';
import { getAuthUser } from './app/services/dashboard';
import { useNavigate } from 'react-router-dom';
import GenericForm from './components/common/FormFields/FormFieldProvider';
import TestingInput from './components/common/FormFields/examples/TestingInput';
import { ZodType, z } from 'zod';

function App() {
  const navigate = useNavigate();
  useEffect(() => {
    var getUser = getAuthUser();
    if (getUser?.token) {
      //requestPermission();
    }
  }, []);

  return (
    <div className="App">
      <MainRouter />
    </div>
  );
}

export default App;
