import classNames from "classnames";
import React, { createRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import calendarIcon from "./img/bi_calendar-week.svg";
import styles from "./DashboardCalendarView.module.scss";
import { StringDropdownList } from "../common/DropdownList";
import trashIcon from "./img/trash-fill.svg";
import saveIcon from "./img/save-icon.svg";
import saveIconHover from "./img/save-icon-hover.svg";
import cancelIcon from "./img/ic_sharp-clear.svg";
import closeIcon from "./img/popup-close.svg";
import { DefaultEvent } from "./DashboardCalendarView";
import DashboardEventContainer from "./DashboardEventContainer";
// import { Modal } from "@material-ui/core";
import { Modal } from "@mui/material";

import { getAuthUser } from "../../app/services/dashboard";
export const renderDateFormatMY = (date: Date, character: string) => {
  var year = date.getFullYear();
  var month = date.toLocaleDateString("default", { month: "short" });
  var day = date.getDate();
  return (month.toString().length < 2 ? "0" + month : month) + character + year;
};
export const renderDateFormatMLongY = (date: Date, character: string) => {
  var year = date.getFullYear();
  var month = date.toLocaleDateString("default", { month: "long" });
  var day = date.getDate();
  return (month.toString().length < 2 ? "0" + month : month) + character + year;
};
export const renderDateFormat = (date: Date, character: string) => {
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  return (
    year +
    character +
    (month.toString().length < 2 ? "0" + month : month) +
    character +
    (day.toString().length < 2 ? "0" + day : day)
  );
};

export const renderDateFormatDMY = (date: Date, character: string) => {
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  return (
    (day.toString().length < 2 ? "0" + day : day) +
    character +
    (month.toString().length < 2 ? "0" + month : month) +
    character +
    year
  );
};

export const renderDateFormatDMYTimeStamp = (date: Date, character: string) => {
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var h = date.getHours();
  var m = date.getMinutes();
  var s = date.getSeconds();
  return (
    (day.toString().length < 2 ? "0" + day : day) +
    character +
    (month.toString().length < 2 ? "0" + month : month) +
    character +
    year +
    ' ' +
    h +
    ":" +
    m +
    ":" +
    s
  );
};
export const renderDateFormatYMD = (date: Date, character: string) => {
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  return (
    year +
    character +
    (month.toString().length < 2 ? "0" + month : month) +
    character +
    (day.toString().length < 2 ? "0" + day : day)
  );
};
const getTime = (d:Date)=>{
  const dd = [d.getHours(), d.getMinutes(), d.getSeconds()].map((a)=>(a < 10 ? '0' + a : a));
  return dd.join(':');
};
export const renderDateFormatYMDTFormat = (date: Date, character: string) => {
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var hour = date.getHours();
  var minutes = date.getMinutes();
  var seconds = date.getSeconds();
  var timezone=date.getTime();
  return (
    year +
    character +
    (month.toString().length < 2 ? "0" + month : month) +
    character +
    (day.toString().length < 2 ? "0" + day : day)+'T'+(date?getTime(date):'')
  );
};

export const renderDateFormatMDY = (date: Date, character: string) => {
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  return (
    (month.toString().length < 2 ? "0" + month : month) +
    character +
    (day.toString().length < 2 ? "0" + day : day) +
    character +
    year
  );
};
interface ISelectedLeave {
  eventID: number;
  leaveID: number;
  indicator: string;
  label: string;
}
interface EventError {
  id: number;
  field: string;
  message: string;
}
const DashboardAddEventPopup = (props: any) => {
  var leaveType = props.leaveType ? props.leaveType : [];
  var eventType = props.eventType ? props.eventType : [];
  var selectedLeave = props.selectLeave ? props.selectLeave : 0;
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  var placeholder = leaveType?.find((x: any) => x.id == selectedLeave);
  const [assign, setAssign] = useState();
  const [eventData, setEventData] = useState<any>([]);
  const [selectLeave, setSelectLeave] = useState<ISelectedLeave>({
    eventID: 0,
    leaveID: 0,
    indicator: "N",
    label: "",
  });
  const [errorList, setErrorList] = useState<EventError[]>([]);
  const [eventTypeError, setEventTypeError] = useState<string>("");
  const [leaveTypeError, setLeaveTypeError] = useState<string>("");
  const [indicatorError, setIndicatorError] = useState<string>("");
  const [empError, setEmpError] = useState<string>("");

  const [eventTypePError, setEventTypePError] = useState<string>("");
  const [projectFieldError, setProjectFieldError] = useState<string>("");
  const [eventTypeHError, setEventTypeHError] = useState<string>("");
  const [holidayDesc, setHolidayDesc] = useState<string>("");
  const [isSaving, setIsSaving] = useState<boolean>(false);
  useEffect(() => {
    // console.log("change props ",eventData)
    if (eventData.length <= 0) {
      setEventData(props.newEvents);
    }
  }, [props.newEvents]);
  useEffect(() => {
    if (selectLeave.leaveID != 0) {
      var leave = eventType.find((x: any) => x.uniqeID == selectLeave.leaveID);
      var data = eventData.map((x: any) => {
        if (x.eventID == selectLeave.eventID) {
          x.eventTypeID = selectLeave.leaveID;
          x.eventType = leave.flag;
          x.indicator = selectLeave.indicator;
          x.eventNote = selectLeave.label == "Note" ? true : false;
          x.projectId = 0;
          x.keyId = selectLeave.leaveID;
        }
        return x;
      });
      setEventData(data);
    }
  }, [selectLeave]);
  useEffect(()=>{
    setEventData(props.newEvents);
  },[props.openNewEventPopup])
  const changeAssignValue = (
    selected: any,
    id: number,
    indicator: string,
    leaveDataType: string
  ) => {
    var evtData = eventData;
    if (eventData.length <= 0) {
      evtData = props.newEvents;
    }
    var data = evtData.map((x: any) => {
      if (id != undefined) {
        if (x.eventID == id) {
          if (selected.target) {
            x.assign = selected.target.value;
            x.date = renderDateFormat(endDate, "-");
            x.start = renderDateFormat(startDate, "-");
            x.end = renderDateFormat(endDate, "-");
            x.TargetId = 0;
            x.indicator = "H";
            if (indicator == "O") {
              x.eventNote = true;
            }
          }
        }
      } else if (x.eventID == selected?.eventID) {
        if (selected?.leaveDataType == "Project") {
          var project = props.projectList.find(
            (e: any) => e.uniqeID == selected?.leaveID
          );
          if (project) {
            x.assign = project ? project.projectName : "";
            x.TargetId = project ? project.uniqeID : "";
            x.projectId = project ? project.uniqeID : "";
            x.eventTarget = "P";
            x.indicator = "P";
          }
        } else if (selected?.leaveDataType == "Leave") {
          var employee = props.employeeData.find(
            (e: any) => e.id == selected?.leaveID
          );
          if (employee) {
            x.assign = employee ? employee.name : "";
            x.TargetId = employee ? employee.id : "";
            x.indicator = "L";
            x.eventTarget = "L";
          }
        }
      }
      return x;
    });
    var allData = [...eventData, ...data];
    //var unique=Array.from(new Set(allData.map((item: any) => item)))
    setEventData(data);
    // props.updateEvents(data);
  };
  const changeLeaveTypeData = (selected: any, id: number) => {
    var data = eventData.map((x: any) => {
      if (x.eventID == selected?.eventID) {
        x.leaveType = selected?.leaveID;
        var indicator = eventType.find(
          (x: any) => x.typeName == selected?.leaveID
        );
        x.indicator = indicator?.flag;
      }
      return x;
    });
    setEventData(data);
    // props.updateEvents(data);
  };
  const addNewEvent = () => {
    var assign = "";
    var eventType = "";
    var eventTypeID = 0;
    var indicator = "L";
    var data = {
      eventID: eventData.length + 1,
      eventType: eventType,
      eventTypeID: eventTypeID,
      assign: assign,
      startDate: renderDateFormat(new Date(), "-"),
      endDate: renderDateFormat(new Date(), "-"),
      eventNote: true,
      indicator: indicator,
    };
    setEventData([...eventData, data]);
    props.addNewEvent(
      assign,
      eventType,
      eventTypeID,
      renderDateFormat(startDate, "-"),
      renderDateFormat(endDate, "-"),
      indicator
    );
  };
  const changeLeaveType = (selectedT: any) => {
    setEventTypeError("");
    setEmpError("");
    setIndicatorError("");
    setLeaveTypeError("");
    setEventTypePError("");
    setProjectFieldError("");
    setEventTypeHError("");
    setHolidayDesc("");
    var changeData = eventData?.map((x: any) => {
      if (x.eventID == selectedT.eventID) {
        return {
          ...x,
          eventType: selectedT.label,
          indicator: selectedT.indicator,
        };
      } else {
        return x;
      }
    });
    setEventData(changeData);
    //setSelectLeave();
  };
  const saveNewEvents = () => {
    setIsSaving(true);
    var data = eventData?.map((x: any) => {
      x.start = renderDateFormat(new Date(x.start), "-");
      x.date = renderDateFormat(new Date(x.start), "-");
      x.end = renderDateFormat(new Date(x.end), "-");
      if (x.indicator == "P") {
        x.end = renderDateFormat(new Date(x.start), "-");
      }
      return x;
    });

    if (data.length > 0) {
      var error = data?.map((d: any, indx: number) => {
        if (d.eventType == "") {
          setEventTypeError("Eventype field is required");
        } else if (d.eventType == "Leave(L)" || d.eventType?.endsWith("L")) {
          setEventTypeError("");
          setEventTypePError("");
          setProjectFieldError("");
          setEventTypeHError("");
          setHolidayDesc("");
          if (d.assign == "" && d.assign == 0) {
            setEmpError("Employee field is required");
          } else {
            setEmpError("");
          }
          if (d.leaveType == "" && d.leaveType == 0) {
            setLeaveTypeError("Leavetype field is required");
          } else {
            setLeaveTypeError("");
          }

          if (d.eventType == "Leave(L)") {
            if (d.eventTypeID == 0) {
              setIndicatorError("Event field is required");
            } else {
              setIndicatorError("");
            }
          } else {
            setIndicatorError("");
          }
        } else if (d.eventType == "Project(P)") {
          setEventTypeError("");
          setEmpError("");
          setIndicatorError("");
          setLeaveTypeError("");
          setEventTypePError("Eventype field is required");
          setProjectFieldError("Project field is required");
          setEventTypeHError("");
          setHolidayDesc("");
        } else if (d.eventType == "Holiday(H)") {
          setEventTypeError("");
          setEmpError("");
          setIndicatorError("");
          setLeaveTypeError("");
          setEventTypePError("");
          setProjectFieldError("");
          setEventTypeHError("Eventype field is required");
          setHolidayDesc("Holiday field is required");
        }
      });
      var show: any = [];
      document
        .querySelectorAll(".eventViewData .errorMsg")
        .forEach((x: any) => {
          if (x.classList.contains("hidden")) {
            show.push(x);
          }
        });
      if (
        document.querySelectorAll(".eventViewData .errorMsg").length ==
        show.length
      ) {
        setEventData(data);
        props.saveNewEvents(data);
        // setEventData([]);
        // props.setNewEventPopup();
      }
    }
    setSelectLeave({ eventID: 0, leaveID: 0, indicator: "N", label: "" });
    setIsSaving(false);    
  };
  const deleteEvents = (id: number) => {
    var evtData = props.newEvents;
    if (eventData.length <= 0) {
      evtData = props.newEvents;
    }
    var data = eventData.filter((x: any) => x.eventID != id);
    setEventData(data);
    props.deleteEventType(id);
  };
  const changeStartDate = (date: Date, id: number) => {
    setStartDate(date);
    setEndDate(date);
    if (eventData.length <= 0) {
      setEventData(props.newEvents);
    }
    eventData.map((ev: any) => {
      if (ev.eventID == id) {
        ev.start = date;
        ev.startDate = date;
        ev.end = date;
        ev.endDate = date;
      }
      return ev;
    });
    setEventData(eventData);
  };
  const changeEndDate = (date: Date, id: number) => {
    setEndDate(date);
    if (eventData.length <= 0) {
      setEventData(props.newEvents);
    }
    eventData.map((ev: any) => {
      if (ev.eventID == id) {
        ev.end = date;
        ev.endDate = date;
      }
      return ev;
    });
    setEventData(eventData);
  };
  return (
    <Modal
      open={props.openNewEventPopup}
      style={{
        background: "rgb(0 0 0 / 0.4)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transform: "all 5s",
      }}
      BackdropProps={{
        timeout: 500,
      }}
      disableEnforceFocus={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {/* <div className={classNames(styles.addNewPopup,props.openNewEventPopup==false?"hidden":'fixed top-0 left-0 h-full w-full overflow-hidden')}> */}
      <div className="modalContent transition-all sm:min-w-[600px]  max-w-[530px] max-h-[98vh] overflow-y-auto xl:max-h-none w-full m-auto px-4 py-8 xl:px-0 xl:py-0 font-primary">
        <div className="w-full bg-white rounded-xl overflow-hidden">
          <div className="relative ">
            <h1
              className={classNames(
                styles.horbar,
                "text-left pl-9 font-poppins font-semibold md:text-lg text-base text-black2 pt-5 mb-3"
              )}
            >
              Add New Event
            </h1>
            <img
              onClick={() => {
                props.setNewEventPopup();
                setEventData([]);
              }}
              src={closeIcon}
              alt="closeIcon"
              className="object-contain cursor-pointer absolute top-5 right-5"
            />
          </div>
          <div className="overflow-x-hidden mt-6 sm:px-9 px-4 eventViewData py-9 bg-white rounded-b-xl max-h-[500px] overflow-y-auto mb-7 pr-3 custom_scrollbar_popup">
            {eventData.map((evt: DefaultEvent, key: number) => {
              return (
                <div key={key}>
                  <DashboardEventContainer
                    changeAssignValue={changeAssignValue}
                    selectedLeave={evt.eventTypeID}
                    setSelectLeave={setSelectLeave}
                    placeholder={evt.eventType}
                    leaveType={eventType}
                    assign={evt.assign}
                    deleteEventType={deleteEvents}
                    id={evt.eventID}
                    employeeData={props.employeeData}
                    changeLeaveTypeData={changeLeaveTypeData}
                    leaveData={evt.leaveType}
                    projectList={props.projectList}
                    startDate={new Date(evt.startDate)}
                    endDate={new Date(evt.endDate)}
                    setEndDate={setEndDate}
                    setStartDate={setStartDate}
                    changeStartDate={changeStartDate}
                    changeEndDate={changeEndDate}
                    addNewEvent={addNewEvent}
                    keyDates={props.keyDates}
                    changeLeaveType={changeLeaveType}
                    eventTypeError={eventTypeError}
                    setEventTypeError={setEventTypeError}
                    leaveTypeError={leaveTypeError}
                    setLeaveTypeError={setLeaveTypeError}
                    indicatorError={indicatorError}
                    setIndicatorError={setIndicatorError}
                    empError={empError}
                    setEmpError={setEmpError}
                    eventTypePError={eventTypePError}
                    setEventTypePError={setEventTypePError}
                    projectFieldError={projectFieldError}
                    setProjectFieldError={setProjectFieldError}
                    holidayDesc={holidayDesc}
                    setHolidayDesc={setHolidayDesc}
                    eventTypeHError={eventTypeHError}
                    setEventTypeHError={setEventTypeHError}
                  />
                </div>
              );
            })}
            <button
              onClick={() => addNewEvent()}
              className="flex cursor-pointer text-left items-center mt-5"
            >
              +
              <span className="font-poppins font-medium text-xs text-black2 ml-2">
                Add New
              </span>
            </button>
            <div className="mt-27 flex justify-end">
              <button
                disabled={
                  eventData.length > 0
                    ? eventData[0].eventID == 0
                      ? false
                      : false
                    : false
                }
                onClick={() => saveNewEvents()}
                className={classNames(
                  styles.saveButton,
                  isSaving == true ? "opacity-50 pointer-events-none" : "",
                  "mr-[10px] rounded-md min-w-[80px] flex justify-center items-center bg-vorpblue hover:bg-vorpmyanmar hover:text-vorpblue px-2 py-1 font-poppins font-medium text-12 text-white"
                )}
              >
                <img
                  src={saveIcon}
                  alt="save icon"
                  className={classNames(styles.defaultIcon, "mr-3")}
                />
                <img
                  src={saveIconHover}
                  alt="save icon"
                  className={classNames(styles.hoverIcon, "mr-2 hidden")}
                />
                Save
              </button>
              <button
                onClick={() => {
                  props.setNewEventPopup();
                  setEventData([]);
                }}
                className="rounded-md min-w-[80px] flex items-center bg-offwhite px-2 py-1 font-poppins font-medium text-12 text-black2"
              >
                <img src={cancelIcon} alt="cancelIcon" className="mr-2" />
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </Modal>
  );
};
export default DashboardAddEventPopup;
