import { redirectToLogin } from "./annoucement-sheet";
import { api, language } from "./api";
import authHeader from "./auth-header";
export type DropdownDataWithLanguage = {
  id: number;
  name_en: string;
  name_tc: string;
  name_sc: string;
};
type DepartmentsResponse = {
  module_id: number;
  status: boolean;
  workGrouplist: TData;
  total: number;
};

export type TData = {
  data: DepartmentInfo[];
  total: number;
};
export type DepartmentInfo = {
  id?: number;
  name: string;
};
type departmentInput = {
  // sort: string;
  // search: string;
};
export type TaskDepartmentList = {
  workGroupList: TaskDepartmentListData[];
};
export type TaskDepartmentListData = {
  id: number;
  name: string;
};

export const workgroupApi = api.injectEndpoints({
  endpoints: (build) => ({
    getWorkgroupList: build.query<
    DepartmentsResponse,
      departmentInput
    >({
      query: () => ({
        url: `workgroups`,
        headers: authHeader(),
      }),
      keepUnusedDataFor:0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    getWorkgroupListAll: build.query<
    TaskDepartmentList,
      void
    >({
      query: () => ({
        url: `getWorkgroupList`,
        headers: authHeader(),
      }),
      keepUnusedDataFor:0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    createWorkgroup: build.mutation<TaskDepartmentList, DropdownDataWithLanguage>({
      query(body) {
        return {
          headers: authHeader(),
          url: `workgroups`,
          body,
          method: "POST",
        };
      }
    }),
    updateWorkgroup: build.mutation<TaskDepartmentList, DropdownDataWithLanguage>({
      query(body) {
        return {
          headers: authHeader(),
          url: `workgroups/${body.id}`,
          body,
          method: "PUT",
        };
      },
      // invalidatesTags: (employees) => {
      //   return [{ type: "Departments", id: "LIST" }];
      // },
    }),
    deleteWorkgroup: build.mutation<TaskDepartmentList, number>({
      query(id) {
        return {
          headers: authHeader(),
          url: `/workgroups/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (employees) => {
        return [{ type: "Departments", id: "LIST" }];
      },
    }),
  }),
});

export const {
  useGetWorkgroupListAllQuery,
  useGetWorkgroupListQuery,
  useCreateWorkgroupMutation,
  useUpdateWorkgroupMutation,
  useDeleteWorkgroupMutation
} = workgroupApi;

export const {
  endpoints: {
    getWorkgroupList,
    getWorkgroupListAll,
    createWorkgroup,
    updateWorkgroup,
    deleteWorkgroup
  },
} = workgroupApi;
