import { Dispatch, FC, SetStateAction } from 'react';
import Styles from './components.module.scss';

interface IToggleSwitch {
  id: string | number;
  state: boolean;
  setState: Dispatch<SetStateAction<boolean>>;
  updateData?: (value: boolean) => void;
  onChange?: (value: boolean) => void;
}

const ToggleSwitch: FC<IToggleSwitch> = ({
  id,
  state,
  setState,
  updateData,
  onChange,
}) => {
  const uuid = String(id);
  return (
    <div className={`w-fit h-fit ${Styles.customSwitch}`}>
      <label
        htmlFor={uuid}
        className="flex items-center cursor-pointer relative"
      >
        <input
          type="checkbox"
          id={uuid}
          name={uuid}
          value={`${state}`}
          className="sr-only"
          onChange={(e) => {
            onChange && onChange(!Boolean(e.target.value === 'true'));
            setState((prev) => {
              updateData && setTimeout(() => updateData(!prev), 300);
              return !prev;
            });
          }}
          {...(state ? { defaultChecked: state } : {})}
        />
        <div className="toggleBg bg-[#EFF2F5] w-10 h-6 sm:h-[30px] sm:w-12 rounded-full after:absolute after:content-[''] after:top-1 after:left-1 after:bg-white after:rounded-full  after:h-4 after:w-4  after:sm:h-[22px] after:sm:w-[22px] transition-all duration-300 after:transition-all after:duration-300"></div>
      </label>
    </div>
  );
};

export default ToggleSwitch;
