import {
  Dispatch,
  FC,
  FocusEvent,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { ChevronDown, DeleteIcon, SearchIcon } from './Icons';
import { uniqueId } from 'lodash';
import { dateRangeFilterFn } from '../../utils/helpers';
import { baseObj } from '../../utils/constants';
import { useDeleteDailyDraftMutation } from '../../app/services/profile-daily-popup';
import { assignedColor } from '../Documents/EmployeeDocumentsTable';
import { employeeColors } from '../../lms/instructor/components/CourseInstructorCardWithDelete';
//import { EmployeeData } from "../EmployeeTable/EmployeeTable";
const classNames = require('classnames');

interface ICustomDropdownList<P = unknown> {
  state: P;
  setState: Dispatch<SetStateAction<P>> | (() => void);
  placeholder?: string;
  dropdownItems?: IListItem[];
  // dropdownItems?: EmployeeData[];
  className?: string;
  paddingClass?: string;
  textAlign?: string;
  dropdownborder?: string;
  isFilter?: boolean;
  fontSize?: string;
  padding?: string;
  // isDisabled?: boolean;
  setSkip?: Dispatch<SetStateAction<boolean>>;
  isOTList?: boolean;
  isMax?: boolean;
  setData?: Dispatch<SetStateAction<any>>;
  id?: number;
  isDelete?: boolean;
  setSuccessPopup?: Dispatch<SetStateAction<boolean>>;
  setErrorPopup?: Dispatch<SetStateAction<boolean>>;
  setMessage?: any;
  setCurrencyLabel?: Dispatch<SetStateAction<string>>;
  isProject?: boolean;
  border?: string;
  color?: string;
  paddingLi?: string;
  isShowAvator?: boolean;
  avator?: string;
  index?: number;
}

interface IListItem {
  name?: string;
  label: string;
  value: string | number | undefined;
  email?: string;
  photo?: string;
  is_draft?: number | string;
}

// Type Factory for Generic Types

const CustomDropdownListFactory = <P extends unknown>() => {
  const ActualInstance: FC<ICustomDropdownList<P>> = ({
    state,
    setState,
    dropdownItems,
    placeholder = 'All3333',
    className = '',
    textAlign = 'text-center',
    dropdownborder = '',
    isFilter,
    fontSize,
    padding,
    paddingClass,
    // isDisabled,
    setSkip,
    isOTList,
    isMax,
    setData,
    id,
    isDelete,
    setSuccessPopup,
    setErrorPopup,
    setMessage,
    setCurrencyLabel,
    isProject,
    border,
    color,
    paddingLi,
    isShowAvator,
    avator,
    index,
  }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [deleteDraft] = useDeleteDailyDraftMutation();

    const [label, setLabel] = useState<string>(
      dropdownItems?.filter((item) => item.value == state)[0]?.label ||
        placeholder
    );

    const uuid = uniqueId('custom-dropdown-list');

    const handleCloseDropdown = (event: FocusEvent<HTMLButtonElement>) => {
      const relatedTarget = event.relatedTarget;
      // If the click target is not the dropdown or dropdown item, close the list.
      if (
        !relatedTarget ||
        !(
          relatedTarget.id === uuid ||
          relatedTarget.className.includes(`${uuid}item`)
        )
      ) {
        setTimeout(() => {
          setIsOpen(false);
        }, 350);
      }
    };

    useEffect(() => {
      const data =
        dropdownItems?.filter((item) => item.value == state)[0]?.label ||
        placeholder;
      setLabel(data);
    }, [dropdownItems, state]);

    const changeDropdownField = (ids: any) => {
      if (setData) {
        setData((prev: baseObj[]) => {
          return prev.map((data: baseObj) => {
            if (data.id == Number(id)) {
              return { ...data, project_id: ids };
            }
            return data;
          });
        });
      }
    };

    const deleteTemplateHandler = (item: any) => {
      try {
        deleteDraft(item)
          .unwrap()
          .then((payload: any) => {
            if (payload.status) {
              if (setSuccessPopup) {
                setSuccessPopup(true);
                setIsOpen(false);
              }
              if (setMessage) {
                setMessage(
                  payload ? [payload?.message] : ['Delete Succcessfully']
                );
              }
            }
          })
          .catch((error: any) => {
            if (setErrorPopup) {
              setErrorPopup(true);
            }
            if (setMessage) {
              setMessage(['Something went wrong! Trying again.']);
            }
          });
      } catch (error) {
        if (setErrorPopup) {
          setErrorPopup(true);
        }
        if (setMessage) {
          setMessage(['Something went wrong! Trying again.']);
        }
      }
    };
    return (
      <div className="relative customDropdownContainer w-full">
        <div
          id={uuid}
          className={classNames(
            dropdownborder,
            `dropdown-hover-btn flex hover:cursor-pointer xl:text-14 text-12 leading-5 relative justify-between  items-center pl-[11px] pr-[11px] border rounded-[5px] custom-dropdown-list`,
            className ? className : 'w-32',
            paddingClass !== '' ? paddingClass : 'xl:py-1 ',
            border ? border : 'border-[#CCDAE4] ',
            color ? color : 'text-graydark '
          )}
          // onClick={() => setIsOpen(!isOpen)}
          onClick={() => setIsOpen(!isOpen)}
          onBlur={(e) => handleCloseDropdown(e as unknown as  FocusEvent<HTMLButtonElement>)}
        >
          <div className="flex items-center">
            {isShowAvator == true ? (
              <div className="mr-4">
                {avator ? (
                  <img
                    src={avator}
                    alt="profile"
                    className="mr-2 rounded-full w-[34px] h-[34px]"
                  />
                ) : (
                  <div
                    style={{
                      backgroundColor: employeeColors[index ? index : 0],
                    }}
                    className="rounded-full w-[34px] h-[34px] flex justify-center items-center body-13 font-medium mr-2 text-white"
                  >
                    {String(label)?.substring(0, 1) ||
                      String(state)?.substring(0, 1) ||
                      placeholder?.substring(0, 1)}
                  </div>
                )}
              </div>
            ) : null}
            <span
              className={
                fontSize && fontSize !== ''
                  ? fontSize
                  : 'md:text-14 sm:text-12 pr-[5px]'
              }
            >
              {isFilter === true
                ? placeholder +
                  (label !== placeholder
                    ? ':' + String(label) || String(state)
                    : '')
                : String(label) || String(state) || placeholder}
            </span>
          </div>
          <ChevronDown />
        </div>
        {isOTList ? (
          <div
            className={classNames(
              'absolute left-0 text-12 max-h-[320px] overflow-y-auto z-40 overflow-hidden top-[104%] w-full border border-[#EFF2F5] bg-white rounded-md',
              { hidden: !isOpen }
            )}
          >
            {dropdownItems?.map((item: IListItem) => (
              <div
                key={Math.random() * 100}
                className={classNames(
                  `px-2 custom-dropdown-item bg-white hover:bg-vorpblue py-[2px] w-full hover:text-white transition-all duration-300 text-graydark ${textAlign} ${
                    uuid + 'item'
                  }`
                )}
                onClick={() => {
                  setState(item?.value as any);
                  setLabel(item?.label);
                  changeDropdownField(item?.value);
                  setIsOpen(false);
                  if (setSkip) {
                    setSkip(false);
                  }
                }}
              >
                {item.label}
              </div>
            ))}
          </div>
        ) : (
          <div
            className={classNames(
              'absolute left-0 text-12 max-h-[250px] overflow-y-auto z-40 overflow-hidden top-[104%] w-full border border-[#EFF2F5] bg-white rounded-md',
              { hidden: !isOpen }
            )}
          >
            {dropdownItems?.map((item: IListItem) => (
              <div
                key={Math.random() * 100}
                className={classNames(
                  item.label == 'No Data' ? 'pointer-events-none' : '',
                  fontSize ? fontSize : '',
                  paddingLi ? paddingLi : 'py-[2px]',
                  `px-2 custom-dropdown-item bg-white hover:bg-vorpblue w-full hover:text-white transition-all duration-300 text-graydark ${textAlign} ${
                    uuid + 'item'
                  }`
                )}
              >
                {isDelete && item.is_draft ? ( //
                  <div className="flex justify-between items-center w-full">
                    <span
                      onClick={() => {
                        setState(item?.value as any);
                        changeDropdownField(item?.value);
                        setLabel(item?.label);
                        setTimeout(() => {
                          setIsOpen(false);
                        }, 300);
                        if (setSkip) {
                          setSkip(false);
                        }
                      }}
                      className="w-4/5 cursor-pointer"
                    >
                      {item.label}
                    </span>
                    <button
                      className={classNames(
                        item.value === 0 || item.value === -1 ? 'hidden' : '',
                        'cust z-[100] cursor-pointer absolute right-2 -translate-y-1/2 w-5 h-5 mt-5'
                      )}
                      onClick={() => {
                        deleteTemplateHandler(item.value);
                      }}
                    >
                      <DeleteIcon color="#fff" />
                    </button>
                  </div>
                ) : (
                  <span
                    onClick={() => {
                      if (isProject) {
                        if (setCurrencyLabel) {
                          setCurrencyLabel(item?.name ?? '');
                        }
                      }
                      setState(item?.value as any);
                      changeDropdownField(item?.value);
                      setLabel(item?.label);
                      setTimeout(() => {
                        setIsOpen(false);
                      }, 300);
                      if (setSkip) {
                        setSkip(false);
                      }
                    }}
                    className="w-full block cursor-pointer"
                  >
                    {item.label}
                  </span>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };
  return ActualInstance;
};

// Extend More for Required Types

export const NumberDropdownList = CustomDropdownListFactory<number>();
export const StringDropdownList = CustomDropdownListFactory<string>();
