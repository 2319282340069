import { PaginationState, SortingState } from "@tanstack/react-table";
import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { baseObj } from "../../utils/constants";
import {
  AddIcon,
  // CalendarIcon,
  CloseIcon,
  CustomizeLayoutIcon,
  ExportIcon,
  // HorizontalGridIcon,
  SearchIcon,
} from "../../components/common/Icons";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
import { NumberDropdownList } from "../../components/common/DropdownList";
import styles from "./ActivityLog.module.scss";
import ActivityLogTable from "../../components/ActivityLog/ActivityLogTable";
import { renderDateFormatYMD } from "../../components/DashboardCalendarView/DashboardAddEventPopup";
// import {
//   useEditDataMutation,
//   // useGetLeaveDataQuery,
// } from "../../app/services/leave-application";
import { StringDropdownListWithDeleteBtn } from "../../components/common/StringDropdownListWithDeleteBtn";
// import { TemplateData } from "../../app/services/employee";
import { useGetByModuleTemplateQuery } from "../../app/services/template";
import { templateList } from "../../active-time-report/views/ActiveTimeReport";
import { hideAllNoneField } from "../../components/Tables/TablePaginator";
// import SuccessPopup from "../../components/SuccessPopup/SuccessPopup";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
// import { useNavigate } from "react-router-dom";
// import { FetchBaseError } from "../../components/common/FetchBaseQueryError";
import { getAuthUser } from "../../app/services/dashboard";
import CustomDropdownListForFilter from "../../components/EmployeeTable/CustomDropdownListForFilter";
import CustomScrollbar from "../../components/CustomScrollbar/CustomScrollbar";
import DatePopupFoFilter from "../../components/EmployeeTable/DatePopupFoFilter";
import { useSpring, animated } from "react-spring";
import CommonTableSkeleton from "../../components/TableSkeleton/CommonTableSkeleton";
import {
  ILogs,
  useGetListUsersDataQuery,
  useGetPasswordLogActivityQuery,
} from "../../app/services/password-log-activity";
import { toast } from "react-toastify";
import { useViewNotificationByTypeMutation } from "../../app/services/notification";
import { useDispatch, useSelector } from "react-redux";
import { setProfileNotification } from "../../reducers/dashboard";
import ResuableToasty from "../../components/Toasty/ResuableToast";
import ExportUsersModal from "../../components/BackendUsers/ExportUsersTable";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
import Button from "../../components/common/Button";
import CustomDatePicker from "../../components/common/BaseDatePicker";
import { useGetModuleListQuery } from "../../app/services/faq";

export interface IleaveType {
  id: number;
  name: string;
  code: string;
}

export interface IKeyValue {
  id: number;
  name?: string;
  label?: string;
}

export interface ILeave {
  appliedAt: string;
  employeeName: string;
  leaveType: string;
  from: string;
  to: string;
  fullHalfDay: string;
  total: number;
  reason: string;
  status: string;
  remark: string;
}

const ActivityLog: React.FC = () => {
  // const navigate = useNavigate();
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [type, setType] = useState("All");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [localModuleId, setLocalModuleId] = useState<number>(0);
  const [getData, setGetData] = useState<baseObj[]>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [leaveStatus, setLeaveStatus] = useState("");
  const [filterModule, setFilterModule] = useState<string>("");
  const { data: logData, isLoading } = useGetPasswordLogActivityQuery({
    search: globalSearch,
    limit: pagination?.pageSize,
    action: type,
    start: startDate === undefined ? "" : renderDateFormatYMD(startDate, "-"),
    end: endDate === undefined ? "" : renderDateFormatYMD(endDate, "-"),
    causer_id: Number(leaveStatus),
    module:filterModule,
    page: currentPage,
    sort: sorting
      .map(
        (s) =>
          `${s.desc ? "-" : ""}${
            s.id == "causer"
              ? "causer_name"
              : s.id == "module"
              ? "log_name"
              : s.id
          }`
      )
      .join(","),
  });
  const { data: userLists } = useGetListUsersDataQuery();
  // const statusListData = [];
  const [currentView, setCurrentView] = useState<any>(0);
  const [currentViewData, setCurrentViewData] = useState<number>(0);
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  const [data, setData] = useState<ILogs[]>([]);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false);
  const typeData = [
    {
      id: 0,
      name: "All",
    },
    {
      id: 1,
      name: "created",
    },
    {
      id: 2,
      name: "updated",
    },
    {
      id: 3,
      name: "deleted",
    },
    {
      id: 4,
      name: "view",
    },
  ];
  const [moduleList, setModuleList] = useState<any>([]);
  
  const [moduleDataList, setModuleDataList] = useState<any>([]);
  const [tempList, setTempList] = useState<any>(templateList);
  const [itemCount, setItemCount] = useState<number>(data?.length);
  const [leaveFullHalfData, setLeaveFullHalfData] = useState<IKeyValue[]>([]);
  const [viewCols, setCols] = useState<any>([]);
  const [isTemplate, setIsTemplate] = useState<boolean>(false);
  const defaultFields = useMemo(
    () => ["created_at", "event", "description", "module", "causer"],
    []
  );
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  const [isLayoutControlOpen, setIsLayoutControlOpen] =
    useState<boolean>(false);
  const { data: templateData } = useGetByModuleTemplateQuery(localModuleId);
  const [viewNotificationByType] = useViewNotificationByTypeMutation();
  const { data: moduleListData } = useGetModuleListQuery();
  const dispatch = useDispatch();
  const stateData: any = useSelector(
    (state: any) => state?.dashboard?.notificationDetail
  );
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [viewPermis, setViewPermis] = useState<boolean>(false);

  var getUser = getAuthUser();
  const getAuthPermis: string[] = useMemo(() => [], []);
  if (getUser?.permissions) {
    getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name);
    });
  }
  useEffect(() => {
    if (moduleListData) {
      setModuleDataList([{module:'all'},...moduleListData?.modules]);
    }
  }, [moduleListData]);

  useEffect(() => {
    setTimeout(() => {
      var stateDataDetail = stateData;
      var updatedData = {
        profile_unread_noti_count: stateDataDetail?.profile_unread_noti_count,
        unapprove_claim_count: stateDataDetail.unapprove_claim_count,
        unapprove_leave_count: stateDataDetail?.unapprove_leave_count,
        unapprove_ot_count: stateDataDetail?.unapprove_ot_count,
        unread_activity_log_count: stateDataDetail?.unread_activity_log_count,
        unread_data_count: {
          unread_password_count:
            stateDataDetail?.unread_data_count?.unread_password_count,
          unread_document_count:
            stateDataDetail?.unread_data_count?.unread_document_count,
          unread_leave_count:
            stateDataDetail?.unread_data_count?.unread_leave_count,
          unread_claim_count:
            stateDataDetail?.unread_data_count?.unread_claim_count,
        },
        unread_count: stateDataDetail?.unread_count,
        notifications: stateDataDetail?.notifications,
      };

      viewNotificationByType("activity_log").then((res) => {
        dispatch(setProfileNotification(updatedData));
      });
    }, 5000);
  }, []);

  useEffect(() => {
    const viewActivity = getAuthPermis.filter((f) => f === "View_Activity_Log");

    if (viewActivity.length > 0) {
      setViewPermis(true);
    }
  }, [getUser, getAuthPermis]);

  useEffect(() => {
    if (userLists?.users) {
      const data = userLists?.users?.map((data: any, index: number) => {
        return {
          value: data.id,
          label: data.name,
        };
      });
      setGetData([{ value: 0, label: "All" }, ...data]);
    }
  }, [userLists?.users]);

  useEffect(() => {
    if (logData?.activity_logs?.data) {
      setData(logData?.activity_logs?.data ?? []);
      setItemCount(logData?.activity_logs?.total);
      setLocalModuleId(logData?.module_id);
    }
  }, [
    logData?.activity_logs?.data,
    logData?.activity_logs?.total,
    logData?.module_id,
    hiddenFields,
    currentView,
  ]);

  useEffect(() => {
    if (templateData) {
      var updatedTemplate = templateData?.templates.map((x: any, i) => {
        return { ...x, index: i + 1 };
      });
      var defaultTemplate = {
        id: -1,
        index: 0,
        module: 0,
        name: "All",
      };
      setModuleList([defaultTemplate, ...updatedTemplate]);
    }
  }, [templateData]);

  useEffect(() => {
    if (templateList) {
      setTempList(templateList);
    }
    var viewData = templateData?.templates.find(
      (x) => x.id.toString() === currentView.toString()
    );
    setHiddenFields(viewData ? [...viewData.viewColumns] : defaultFields);
    hideAllNoneField();
  }, [currentView, defaultFields]);

  useEffect(() => {
    if (viewCols?.view_columns?.length > 0) {
      setHiddenFields(viewCols?.view_columns);
    }
  }, [viewCols?.view_columns]);

  useEffect(() => {
    if (moduleList) {
      var lastesTemplate = moduleList[moduleList?.length - 1];
      setCurrentViewData(lastesTemplate ? Number(lastesTemplate?.id) : 0);
    }
  }, [moduleList]);

  useEffect(() => {
    setCurrentPage(1);
  }, [
    globalSearch,
    type,
    leaveStatus,
    startDate,
    endDate,
    pagination.pageSize,
  ]);

  useEffect(() => {
    if (successPopup) {
      ResuableToasty({
        message: "Delete Successfully.",
        position: "top-right",
        type: "success",
      });

      // toast("Delete Successfully.", {
      //   position: "top-right",
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   type: "success",
      // });
    }

    setTimeout(() => {
      setSuccessPopup(false);
    }, 2000);
  }, [successPopup]);

  const deleteTemplateData = (id: string) => {
    // var filterTempList = templateList.filter((x) => x.id !== parseInt(id));
    // setTempList(filterTempList);
  };

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 1000,
  });
  const exportEmployeeData = (format: string, isChecked: string) => {
    axios({
      url:
        endpointUrl +
        `activity-logs?limit=${
          pagination.pageSize
        }&page=${currentPage}&globalSearch=${globalSearch}&start=${
          startDate ? renderDateFormatYMD(startDate, "/") : ""
        }&end=${
          endDate ? renderDateFormatYMD(endDate, "/") : ""
        }&format=${format}&export=true&only_this_page=${isChecked}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${getUser.token}`,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "ActivityLog." + (format === "excel" ? "xlsx" : format)
        );
        document.body.appendChild(link);
        link.click();
        setIsExporting(false);
        setIsExportModalOpen(false);
      })
      .catch((error: any) => {
        setErrorPopup(true);
        setMessage(
          error?.message
            ? [error?.message]
            : ["Something wrong!. Trying again."]
        );
        setIsExportModalOpen(false);
      });
  };

  const downloadSampleFile = () => {
    axios({
      url: endpointUrl + "downloadActiveTimeSample",
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "ActivityLog.xlsx");
      document.body.appendChild(link);
      link.click();
      setIsDownloading(false);
    });
  };
  return (
    <>
      {isLoading ? (
        <CommonTableSkeleton rows={12} />
      ) : (
        <>
          <ExportUsersModal
            isOpen={isExportModalOpen}
            setIsOpen={setIsExportModalOpen}
            exportToCSV={exportEmployeeData}
            exportToExcel={exportEmployeeData}
            exportToPDF={exportEmployeeData}
            exportToZip={exportEmployeeData}
            isExporting={isExporting}
            setIsExporting={setIsExporting}
            isShowOnlyCheckbox={true}
          />
          <ErrorPopup
            descText="Error Message!"
            confirmBtnText="Yes, Close."
            show={errorPopup}
            setShow={setErrorPopup}
            message={message}
            setMessage={setMessage}
          />
          <div
            className={classNames(
              "px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
            )}
          >
            <div className="">
              <div className="flex flex-wrap items-center">
                <div className="flex flex-wrap justify-between items-center w-full">
                  <div>
                    <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[200px] my-1">
                      <DebounceSearchInput
                        setState={setGlobalSearch}
                        debounce={800}
                      >
                        {(setState) => (
                          <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                            <input
                              type="text"
                              placeholder="Search"
                              className="font-normal focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                              onChange={(e) => setState(e.target.value)}
                              defaultValue=""
                            />
                            <SearchIcon className={classNames("mr-2")} />
                          </div>
                        )}
                      </DebounceSearchInput>
                    </div>
                    <div></div>
                  </div>
                  <Button
                    label={
                      <span className="flex items-center">
                        <ExportIcon className="mr-[5px]" /> Export
                      </span>
                    }
                    onClick={() => {
                      setIsExportModalOpen(true);
                      setIsExporting(false);
                    }}
                    type="button"
                    variant="no-outline"
                    customClass="my-1 bg-vorpmyanmar border-vorpmyanmar hover:bg-vorpblue hover:text-white transition-all duration-300 text-vorpblue xs:text-[12px] leading-[18px] font-medium py-1 px-[6px] sm:mr-[10px] mr-[5px]"
                    size="base"
                  />
                </div>
              </div>
              <div className="flex sm:flex-row flex-col justify-between">
                <div
                  className={classNames(
                    "flex sm:flex-row flex-col w-full items-center"
                  )}
                >
                  <div className="flex w-full mt-1 md:mb-1 sm:mr-5 custom-scrollbar">
                    <div className="flex items-center w-auto mr-2">
                      <p className="hidden whitespace-nowrap sm:w-auto 2xs:w-[20%] w-1/2 text-left  uppercase xl:text-sm text-12 leading-5 font-medium text-black2 mr-[10px]">
                        Date
                      </p>
                      <div className="flex flex-row w-full">
                        {startDate && endDate && (
                          <button
                            type="button"
                            onClick={() => {
                              setStartDate(undefined);
                              setEndDate(undefined);
                            }}
                            className="text-12 mr-2"
                          >
                            <CloseIcon color="#EE436D" width={10} height={10} />
                          </button>
                        )}
                        <div className={` mr-[10px] htzxs:w-auto w-full`}>
                          <DatePopupFoFilter
                            placeholder="Start"
                            date={startDate}
                            setDate={setStartDate}
                          />
                        </div>
                        <div className={`htzxs:w-auto w-full`}>
                          <DatePopupFoFilter
                            placeholder="End"
                            date={endDate}
                            setDate={(value) => setEndDate(value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                    className={classNames(
                      styles.permissionDropdown,
                      "flex items-center mr-[10px] sm:w-auto w-full"
                    )}
                  >
                    <p className="hidden sm:w-auto w-1/2 text-left font-poppins font-medium  xl:text-sm text-12 text-[#464E5F] mr-1">
                      Module
                    </p>
                    <CustomDropdownListForFilter
                      placeholder="Module"
                      state={filterModule}
                      setState={setFilterModule}
                      dropdownItems={
                        moduleDataList
                          ? moduleDataList.map((dp: any) => ({
                              value: dp.module,
                              label: dp.module,
                            }))
                          : []
                      }
                      textAlign="text-left"
                      className="max-h-[38px] max-w-full sm:max-w-[250px] 2xl:w-32 sm:w-28 w-full"
                    />
                  </div>
                  </div>
                  
                </div>
                <div
                  className={classNames(
                    "flex justify-end sm:w-auto w-full sm:flex-nowrap flex-wrap items-center sm:mt-0 mt-2 mb-1"
                  )}
                >
                  <div className="flex items-center md:ml-3 mr-[10px]">
                    <p className="base-label hidden 3xs:block htzxs:hidden sm:block xl:text-sm text-12">
                      DISPLAY
                    </p>
                    <NumberDropdownList
                      state={pagination.pageSize}
                      setState={(value) =>
                        setPagination((prev) => ({
                          ...prev,
                          pageSize: value as number,
                        }))
                      }
                      dropdownItems={new Array(5)
                        .fill(1)
                        .map((_, index: number) => {
                          let display = (index + 1) * 20;
                          return {
                            value: display,
                            label: String(display),
                          };
                        })}
                      className="max-w-[55px] 4xs:max-w-[60px] lg:max-w-[72px]"
                      textAlign="text-left pl-3"
                      paddingClass="py-[2px]"
                      placeholder="20"
                    />
                  </div>
                  <div className="flex justify-end">
                    <StringDropdownListWithDeleteBtn
                      haveDeleteBtn={true}
                      setIsTemplate={setIsTemplate}
                      state={isTemplate ? currentViewData : currentView}
                      setState={setCurrentView}
                      deleteTemplate={deleteTemplateData}
                      dropdownItems={moduleList.map((x: any, index: number) => {
                        return {
                          label: x.id === -1 ? x.name : "View #" + x.index,
                          value: x.id,
                        };
                      })}
                      setErrorPopup={setErrorPopup}
                      setSuccessPopup={setSuccessPopup}
                      setMessage={setMessage}
                      className="w-full min-w-[100px] 2xl:min-w-[128px] 6xs:text-12"
                      placeholder="View #1"
                      custPadding="py-[2px]"
                      textAlign="text-left py-2 pl-2 pr-3"
                    />
                  </div>
                  <button
                    type="button"
                    onClick={() => setIsLayoutControlOpen(true)}
                    className="flex justify-center relative items-center rounded-lg bg-vorphongkongborder hover:bg-[#FFF0D2] w-[29px] h-[29px] ml-2 group transition-all duration-300 "
                  >
                    <CustomizeLayoutIcon />
                    <div className="absolute left-7 top-[-5px] border border-white w-4 h-4 rounded-full bg-vorphongkongborder flex justify-center items-center">
                      <AddIcon width={8} height={8} color="white" />
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div className="text-left md:mt-0 mt-2">
              <animated.div style={props}>
                <ActivityLogTable
                  data={data}
                  setData={setData}
                  pagination={pagination}
                  setPagination={setPagination}
                  isLayoutControlOpen={isLayoutControlOpen}
                  setIsLayoutControlOpen={setIsLayoutControlOpen}
                  globalSearch={globalSearch}
                  setGlobalSearch={setGlobalSearch}
                  leaveFullHalfData={leaveFullHalfData}
                  setLeaveFullHalfData={setLeaveFullHalfData}
                  hiddenFields={hiddenFields ? hiddenFields : []}
                  setHiddenFields={setHiddenFields}
                  templateList={tempList}
                  localModuleId={localModuleId}
                  setCurrentView={setCurrentView}
                  setCols={setCols}
                  // changePage={changePage}
                  total={itemCount}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  setIsTemplate={setIsTemplate}
                  setIsEdit={setIsEdit}
                  isEditing={isEdit}
                  sorting={sorting}
                  setSorting={setSorting}
                />
              </animated.div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default ActivityLog;
