import React, {
  // Component,
  Dispatch,
  FC,
  SetStateAction,
  useState,
  useRef,
  useEffect,
  useCallback,
  // useCallback,
} from "react";
import { Modal, Fade, CircularProgress } from "@mui/material";
import "tinymce/skins/ui/oxide/skin.min.css";
import Styles from "../../components/common/components.module.scss";
import CustomDatePicker from "../common/FullWidthDatePicker";
import { StringDropdownList } from "../common/DropdownList";
import whiteCancel from "../common/img/white-cancel.svg";
import blackCancel from "../common/img/black-cancel.svg";
import saveIcon from "./img/submit-icon.svg";
import { useGetTagsDataCustomQuery } from "../../app/services/annoucement-sheet";
import { CommonTagDropdown } from "../common/CommonTagDropdown";
import DOMPurify from "dompurify";
import classNames from "classnames";
import {
  renderDateFormatYMD,
  renderDateFormatYMDTFormat,
} from "../DashboardCalendarView/DashboardAddEventPopup";
import { TaskCategoriesList } from "../../app/services/category";
import CommonLanguage from "../common/CommonLanguage";
import { twJoin } from "tailwind-merge";
import {
  ClassicEditor,
  Context,
  InlineEditor,
  ContextWatchdog,
  Autoformat,
  Bold,
  Italic,
  BlockQuote,
  Base64UploadAdapter,
  CKFinder,
  CKFinderUploadAdapter,
  CloudServices,
  EditorConfig,
  Essentials,
  Heading,
  Image,
  ImageCaption,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  PictureEditing,
  Indent,
  Link,
  List,
  Mention,
  Paragraph,
  PasteFromOffice,
  Table,
  TableToolbar,
  TextTransformation,
} from "ckeditor5";

import { CKEditor, CKEditorContext } from "@ckeditor/ckeditor5-react";

import "ckeditor5/ckeditor5.css";
import {
  DesktopTimePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
export const typeList = ["Caution", "Notice", "Warning"];

interface IModalAnnounEditor {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  valueChangeCallback: (value: any, id: string) => Promise<void>;
  totalLength: number;
  loggedUser: string;
  createTagData?: (label: string) => void;
  saving?: boolean;
  setSaving?: Dispatch<SetStateAction<boolean>>;
  departments: any;
  categories: any;
  workgroups: any;
  employeeList: any;
  editRowData: any;
}

// interface IFilterStates {
//   tag: string;
// }
const NoticeBoardModalEditor: FC<IModalAnnounEditor> = ({
  isOpen,
  setIsOpen,
  valueChangeCallback,
  totalLength,
  loggedUser,
  createTagData,
  saving,
  setSaving,
  departments,
  categories,
  employeeList,
  editRowData,
  workgroups,
}) => {
  // const tags = categories?.categoryList
  //   // .filter((x: any) => x.flag === "anno" || x.flag === null)
  //   ?.map((tag: any, idx: any) => ({
  //     label: tag.name_en,
  //     value: tag.id,
  //   }))
  //   .filter((tag: any) => tag.label.toLowerCase() !== "all");

  const editorRef_en: any = useRef(null);
  const editorRef_tc: any = useRef(null);
  const editorRef_sc: any = useRef(null);
  const [goalTitle_en, setGoalTitle_en] = useState("");
  const [goalTitle_tc, setGoalTitle_tc] = useState("");
  const [goalTitle_sc, setGoalTitle_sc] = useState("");
  const [departmentError, setDepartmentError] = useState("");
  const [workgroupError, setWorkgroupError] = useState("");
  const [employeeError, setEmployeeError] = useState("");
  const [chosenTags, setChosenTags] = useState<string[]>([]);
  const [status, setStatus] = useState<boolean>(false);
  const [selectedCategoryData, setSelectedCategoryData] = useState<string[]>(
    []
  );
  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);
  const [selectedDepartmentsData, setSelectedDepartmentsData] = useState<
    string[]
  >([]);
  const [selectedWorkgroups, setSelectedWorkgroups] = useState<string[]>([]);
  const [selectedWorkgroupsData, setSelectedWorkgroupsData] = useState<
    string[]
  >([]);
  const [selectedEmployees, setSelectedEmployees] = useState<string[]>([]);
  const [selectedEmployeesData, setSelectedEmployeesData] = useState<string[]>(
    []
  );
  const [employees, setEmployees] = useState<any>(employeeList);
  const [postedDate, setPostedDate] = useState(new Date());
  const [expiryDate, setExpiryDate] = useState<Date>();
  const [publishDate, setPublishDate] = useState<Date>();
  const [publishTime, setPublishTime] = useState<Date>();
  const [expireTime, setExpireTime] = useState<Date>();
  const [myGoal_en, setMyGoal_en] = useState("");
  const [myGoal_tc, setMyGoal_tc] = useState("");
  const [myGoal_sc, setMyGoal_sc] = useState("");
  // const [loading, setLoading] = useState(true);
  const [titleError, setTitleError] = useState("");
  const [contentError, setContentError] = useState("");
  const [expireError, setExpireError] = useState("");
  const [tagError, setTagError] = useState("");
  const [typeData, setTypeData] = useState(typeList[0]);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [uploading, setUploading] = useState<boolean>(false);
  const [selectedFileName, setSelectedFileName] = useState("No file chosen");
  const [allFile, setFile] = useState<any>({});
  const [allPrevFiles, setAllPrevFiles] = useState<any[]>([]);
  const [oldDocuments, setOldDocuments] = useState<any[]>([]);
  const [activeLang, setActiveLang] = useState<string>("en");
  const [id, setID] = useState<string>("");
  const uploadRef: any = useRef(null);
  const goalTitleHandler = (event: any, language: string) => {
    if (language == "en") setGoalTitle_en(event.target.value);
    if (language == "tc") setGoalTitle_tc(event.target.value);
    if (language == "sc") setGoalTitle_sc(event.target.value);
  };

  const handleEditorChange = (event: any, editor: any) => {
    if (activeLang == "en") setMyGoal_en(editor.getData());
    if (activeLang == "tc") setMyGoal_tc(editor.getData());
    if (activeLang == "sc") setMyGoal_sc(editor.getData());
  };

  const testingHandler = (event: any, isDraft: boolean) => {
    if (setSaving) {
      if (isDraft == false) setSaving(true);
    }
    if (
      goalTitle_en == undefined ||
      goalTitle_en === "" ||
      goalTitle_tc == undefined ||
      goalTitle_tc === "" ||
      goalTitle_sc == undefined ||
      goalTitle_sc === ""
    ) {
      setTitleError("Title field is required");
      if (setSaving) {
        setSaving(false);
      }
      return;
    } else {
      setTitleError("");
    }
    if (expiryDate === null) {
      setExpiryDate(new Date());
      //setExpireError("ExpireData field is required");
      return;
    }
    if (publishDate === null) {
      setPublishDate(new Date());
    }
    if (
      myGoal_en === "" ||
      myGoal_en === undefined ||
      myGoal_tc === "" ||
      myGoal_tc === undefined ||
      myGoal_sc === "" ||
      myGoal_sc === undefined
    ) {
      if (setSaving) {
        setSaving(false);
      }
      setContentError("Content field is required");
      return;
    } else {
      setContentError("");
    }

    if (!selectedDepartments || selectedDepartments.length <= 0) {
      if (setSaving) {
        setSaving(false);
      }
      setDepartmentError("Department is required");
      return;
    } else {
      setDepartmentError("");
    }
    // if (!selectedWorkgroups || selectedWorkgroups.length <= 0) {
    //   if (setSaving) {
    //     setSaving(false);
    //   }
    //   setWorkgroupError("Department is required");
    //   return;
    // } else {
    //   setWorkgroupError("");
    // }
    if (!selectedEmployees || selectedEmployees.length <= 0) {
      if (setSaving) {
        setSaving(false);
      }
      setEmployeeError("Employee is required");
      return;
    } else {
      setEmployeeError("");
    }
    if (!chosenTags || chosenTags.length <= 0) {
      if (setSaving) {
        setSaving(false);
      }
      setTagError("Category is required");
      return;
    } else {
      setTagError("");
    }
    setTitleError("");
    setExpireError("");
    setContentError("");
    setTagError("");

    const publishDateData = publishDate
      ? renderDateFormatYMDTFormat(publishDate, "-").split("T")
      : "";
    const publishTimeData = dayjs(publishTime)
      ? dayjs(publishTime).format("YYYY-MM-DDTHH:mm:ss").toString().split("T")
      : "";
    const publish_at = publishDateData[0] + "T" + publishTimeData[1];

    const expiredDateData = expiryDate
      ? renderDateFormatYMDTFormat(expiryDate, "-").split("T")
      : "";
    const expiredTimeData = dayjs(expireTime)
      ? dayjs(expireTime).format("YYYY-MM-DDTHH:mm:ss").toString().split("T")
      : "";
    const expired_at = expiredDateData[0] + "T" + expiredTimeData[1];
    const saveData = {
      name_en: goalTitle_en,
      name_tc: goalTitle_tc,
      name_sc: goalTitle_sc,
      description_en: myGoal_en,
      description_tc: myGoal_tc,
      description_sc: myGoal_sc,
      file: uploadRef?.current?.files[0],
      categories: selectedCategoryData.find((x: any) => x.name == "All")
        ? "all"
        : selectedCategoryData?.map((x: any) => x.id).join(","),
      employee: selectedEmployeesData.find((x: any) => x.name == "All")
        ? "all"
        : selectedEmployeesData?.map((x: any) => x.id).join(","),
      departments: selectedDepartmentsData.find((x: any) => x.name == "All")
        ? "all"
        : selectedDepartmentsData?.map((x: any) => x.id).join(","),
      workgroups: selectedWorkgroupsData.find((x: any) => x.name == "All")
        ? "all"
        : selectedWorkgroupsData?.map((x: any) => x.id).join(","),
      expired_at: expired_at,
      published_at: publish_at,
      is_download: status == true ? 1 : 0,
    };
    let formSaveData = new FormData();
    formSaveData.append("name_en", saveData.name_en);
    formSaveData.append("name_tc", saveData.name_tc);
    formSaveData.append("name_sc", saveData.name_sc);
    formSaveData.append("description_en", saveData.description_en);
    formSaveData.append("description_tc", saveData.description_tc);
    formSaveData.append("description_sc", saveData.description_sc);
    formSaveData.append("categories", saveData.categories);
    formSaveData.append("employees", saveData.employee);
    formSaveData.append("departments", saveData.departments);
    formSaveData.append("workgroups", saveData.workgroups);
    formSaveData.append("file", saveData.file);
    formSaveData.append("expired_at", saveData.expired_at);
    formSaveData.append("published_at", saveData.published_at);
    formSaveData.append("save_draft", isDraft == true ? "1" : "0");
    formSaveData.append("is_download", saveData.is_download.toString());
    valueChangeCallback(formSaveData, id);
  };

  // useEffect(() => {
  //   if (editorRef_en.current) {
  //     setMyGoal_en(editorRef_en.current.getContent());
  //   }
  // }, [editorRef_en]);
  // useEffect(() => {
  //   if (editorRef_tc.current) {
  //     setMyGoal_tc(editorRef_tc.current.getContent());
  //   }
  // }, [editorRef_tc]);
  // useEffect(() => {
  //   if (editorRef_sc.current) {
  //     setMyGoal_sc(editorRef_sc.current.getContent());
  //   }
  // }, [editorRef_sc]);
  const UploadFile = useCallback(() => {
    const file = uploadRef?.current.files[0];
    setFile(file);
    setSelectedFileName(file.name);
    const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB
    if (!file) {
      setErrorMsg("Please choose a file");
      return;
    }
    for (let i = 0; i < file.length; i++) {
      if (file[i].size > MAX_FILE_SIZE) {
        setErrorMsg("File size exceeds the maximum allowed (100MB).");
        return;
      } else {
        setErrorMsg("");
      }
    }
  }, [setSelectedFileName, setFile, setErrorMsg]);
  useEffect(() => {
    if (employeeList) {
      setEmployees([...employeeList]);
    }
    if (selectedDepartmentsData.length > 0) {
      setEmployees([
        { id: "all", name: "All", department_id: 0 },
        ...employeeList?.filter((emp: any) => {
          if (
            selectedDepartmentsData.find((x: any) => x.id == emp.department_id)
          ) {
            return emp;
          }
        }),
      ]);
    }
  }, [employeeList, selectedDepartmentsData]);
  const clearData = () => {
    setGoalTitle_en("");
    setGoalTitle_tc("");
    setGoalTitle_sc("");
    setChosenTags([]);
    setSelectedWorkgroups([]);
    setSelectedWorkgroupsData([]);
    setSelectedCategoryData([]);
    setSelectedDepartments([]);
    setSelectedDepartmentsData([]);
    setSelectedEmployees([]);
    setSelectedEmployeesData([]);
    setPostedDate(new Date());
    setExpiryDate(new Date());
    setPublishDate(new Date());
    setMyGoal_en("");
    setMyGoal_tc("");
    setMyGoal_sc("");
    if (setSaving) setSaving(false);
    setPublishTime(new Date());
    setExpireTime(new Date());
    setActiveLang("en");
    setStatus(false);
  };
  useEffect(() => {
    setActiveLang("en");
    setSelectedFileName("");
    setFile({});
    if (isOpen === false) {
      // setGoalTitle_en("");
      // setGoalTitle_tc("");
      // setGoalTitle_sc("");
      // setChosenTags([]);
      // setSelectedCategoryData([]);
      // setSelectedDepartments([]);
      // setSelectedDepartmentsData([]);
      // setSelectedEmployees([]);
      // setSelectedEmployeesData([]);
      // setPostedDate(new Date());
      // setExpiryDate(new Date());
      // setPublishDate(new Date());
      // setMyGoal_en("");
      // setMyGoal_tc("");
      // setMyGoal_sc("");
      if (setSaving) setSaving(false);
      setActiveLang("en");
      if (!editRowData) {
        clearData();
        console.log("clearData ", editRowData, selectedEmployees);
      }
    }
  }, [isOpen, saving]);

  const handleClick = () => {
    uploadRef.current.click();
  };
  useEffect(() => {
    if (editRowData) {
      if (editRowData.id == 0) {
        clearData();
        return;
      }
      const file = editRowData?.file_url?.split("/");
      setSelectedFileName(file ? file[file.length - 1] : "");
      setID(editRowData.id);
      setGoalTitle_en(editRowData.name_en);
      setGoalTitle_tc(editRowData.name_tc);
      setGoalTitle_sc(editRowData.name_sc);
      setMyGoal_en(editRowData.description_en);
      setMyGoal_tc(editRowData.description_tc);
      setMyGoal_sc(editRowData.description_sc);
      if (editRowData?.categories.length == categories.length - 1) {
        setChosenTags(["All"]);
        setSelectedCategoryData(
          categories
            ?.filter((x: any) => x.id == "all")
            .map((cat: any) => {
              return {
                id: cat.id,
                name: cat.name_en,
              };
            })
        );
      } else {
        setChosenTags(editRowData?.categories?.map((cat: any) => cat.name_en));
        setSelectedCategoryData(
          editRowData?.categories?.map((cat: any) => {
            return {
              id: cat.id,
              name: cat.name_en,
            };
          })
        );
      }
      if (departments.length - 1 == editRowData?.departments.length) {
        setSelectedDepartments(["All"]);
        setSelectedDepartmentsData(
          departments
            ?.filter((x: any) => x.id == "all")
            .map((cat: any) => {
              return {
                id: cat.id,
                name: cat.name_en,
              };
            })
        );
      } else {
        setSelectedDepartments(
          editRowData?.departments?.map((cat: any) => cat.name_en)
        );
        setSelectedDepartmentsData(
          editRowData?.departments?.map((cat: any) => {
            return {
              id: cat.id,
              name: cat.name_en,
            };
          })
        );
      }

      if (editRowData?.workgroups.length == workgroups.length - 1) {
        setSelectedWorkgroups(["All"]);
        setSelectedWorkgroupsData(
          workgroups
            ?.filter((x: any) => x.id == "all")
            .map((cat: any) => {
              return {
                id: cat.id,
                name: cat.name_en,
              };
            })
        );
      } else {
        setSelectedWorkgroups(
          editRowData?.workgroups?.map((cat: any) => cat.name_en)
        );
        setSelectedWorkgroupsData(
          editRowData?.workgroups?.map((cat: any) => {
            return {
              id: cat.id,
              name: cat.name_en,
            };
          })
        );
      }

      const filterEmp = [
        ...employeeList?.filter((emp: any) => {
          if (
            editRowData?.departments?.find(
              (x: any) => x.id == emp.department_id
            )
          ) {
            return emp;
          }
        }),
      ];
      if (editRowData?.employees) {
        if (editRowData?.employees.length == filterEmp.length) {
          setSelectedEmployees(["All"]);
          setSelectedEmployeesData(
            employeeList?.filter((x: any) => x.id == "all")
          );
        } else {
          setSelectedEmployees(
            editRowData?.employees?.map((cat: any) => cat.name_en)
          );
          setSelectedEmployeesData(
            editRowData?.employees?.map((cat: any) => {
              return {
                id: cat.id,
                name: cat.name_en,
              };
            })
          );
        }
      }
      setExpiryDate(
        editRowData.expired_at ? new Date(editRowData.expired_at) : new Date()
      );
      setPublishDate(
        editRowData.published_at
          ? new Date(editRowData.published_at)
          : new Date()
      );
      setEmployees([{ id: "all", name: "All" }, ...filterEmp]);
      setExpireTime(editRowData.expired_at);
      setPublishTime(editRowData.published_at);
      setStatus(editRowData.is_download == 1 ? true : false);
    }
  }, [editRowData]);
  const changeLanguage = (code: string) => {
    setActiveLang(code);
  };
  return (
    <Modal
      open={isOpen}
      style={{
        background: "rgb(0 0 0 / 0.3)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          clearData();
          // Set 'open' to false, however you would do that with your particular code.
          setIsOpen(false);
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus={true}
    >
      <Fade in={isOpen} timeout={500}>
        <div className="modalContent w-full xl:max-w-[1200px] max-w-[90%] annoucement-modalw-full px-5">
          <div className="">
            <div
              id="modal-modal-title"
              className="bg-vorpblue relative flex justify-between py-6 sm:px-10 px-5 rounded-t-xl"
            >
              <h3 className="text-18 font-primary font-semibold text-white">
                Notice Board
              </h3>
              <button
                className="flex items-center justify-center"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <img src={whiteCancel} alt="close modal icon" />
              </button>
            </div>
            <div
              id="modal-modal-description"
              className="pb-9 px-5 bg-white rounded-b-xl  overflow-y-auto max-h-[70vh] h-full custom_scrollbar_popup"
            >
              <CommonLanguage
                changeLanguage={changeLanguage}
                activeLang={activeLang}
              />
              <div className="md:flex pt-5">
                <div className="md:w-1/2 w-full sm:pl-3">
                  <div className={twJoin(activeLang == "en" ? "en" : "opacity-0 pointer-events-none max-h-0 overflow-hidden")}>
                    <div className="mb-5 last:mb-0">
                      <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                        Title
                      </label>
                      <input
                        type="text"
                        name="goalTitle_en"
                        value={goalTitle_en}
                        onChange={(event) => goalTitleHandler(event, "en")}
                        placeholder="Title"
                        className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
                      ></input>
                      <p
                        className={classNames(
                          titleError !== "" ? "" : "hidden",
                          "mt-2 font-poppins text-12 text-redPink"
                        )}
                      >
                        {titleError ? titleError : ""}
                      </p>
                    </div>
                    <div className="mb-5 last:mb-0 h-full">
                      <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                        Content
                      </label>
                      <CKEditorContext
                        context={Context}
                        contextWatchdog={ContextWatchdog}
                      >
                        <CKEditor
                          editor={ClassicEditor}
                          ref={editorRef_en}
                          config={{
                            plugins: [
                              Essentials,
                              Autoformat,
                              Bold,
                              Italic,
                              BlockQuote,
                              CKFinder,
                              CKFinderUploadAdapter,
                              CloudServices,
                              Heading,
                              Image,
                              ImageCaption,
                              ImageStyle,
                              ImageToolbar,
                              ImageUpload,
                              Base64UploadAdapter,
                              Indent,
                              Link,
                              List,
                              Mention,
                              Paragraph,
                              PasteFromOffice,
                              PictureEditing,
                              Table,
                              TableToolbar,
                              TextTransformation,
                            ],
                            toolbar: [
                              "undo",
                              "redo",
                              "|",
                              "heading",
                              "|",
                              "bold",
                              "italic",
                              "|",
                              "link",
                              "uploadImage",
                              "insertTable",
                              "blockQuote",
                              "|",
                              "bulletedList",
                              "numberedList",
                              "|",
                              "outdent",
                              "indent",
                            ],
                          }}
                          data={myGoal_en ? myGoal_en : "<p></p>"}
                          onReady={(editor) => {}}
                          onBlur={handleEditorChange}
                        />
                      </CKEditorContext>
                      <p
                        className={classNames(
                          contentError !== "" ? "" : "hidden",
                          "mt-2 font-poppins text-12 text-redPink"
                        )}
                      >
                        {contentError ? contentError : ""}
                      </p>
                    </div>
                  </div>
                  <div className={twJoin(activeLang == "tc" ? "tc" : "opacity-0 pointer-events-none max-h-0 overflow-hidden")}>
                    <div className="mb-5 last:mb-0">
                      <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                        Title
                      </label>
                      <input
                        type="text"
                        name="goalTitle_tc"
                        value={goalTitle_tc}
                        onChange={(event) => goalTitleHandler(event, "tc")}
                        placeholder="Title"
                        className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
                      ></input>
                      <p
                        className={classNames(
                          titleError !== "" ? "" : "hidden",
                          "mt-2 font-poppins text-12 text-redPink"
                        )}
                      >
                        {titleError ? titleError : ""}
                      </p>
                    </div>
                    <div className="mb-5 last:mb-0 h-full">
                      <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                        Content
                      </label>
                      <CKEditorContext
                        context={Context}
                        contextWatchdog={ContextWatchdog}
                      >
                        <CKEditor
                          ref={editorRef_tc}
                          editor={ClassicEditor}
                          config={{
                            plugins: [
                              Essentials,
                              Autoformat,
                              Bold,
                              Italic,
                              BlockQuote,
                              CKFinder,
                              CKFinderUploadAdapter,
                              CloudServices,
                              Heading,
                              Image,
                              ImageCaption,
                              ImageStyle,
                              ImageToolbar,
                              ImageUpload,
                              Base64UploadAdapter,
                              Indent,
                              Link,
                              List,
                              Mention,
                              Paragraph,
                              PasteFromOffice,
                              PictureEditing,
                              Table,
                              TableToolbar,
                              TextTransformation,
                            ],
                            toolbar: [
                              "undo",
                              "redo",
                              "|",
                              "heading",
                              "|",
                              "bold",
                              "italic",
                              "|",
                              "link",
                              "uploadImage",
                              "insertTable",
                              "blockQuote",
                              "|",
                              "bulletedList",
                              "numberedList",
                              "|",
                              "outdent",
                              "indent",
                            ],
                          }}
                          data={myGoal_tc ? myGoal_tc : "<p></p>"}
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                          }}
                          onBlur={handleEditorChange}
                        />
                      </CKEditorContext>
                      <p
                        className={classNames(
                          contentError !== "" ? "" : "hidden",
                          "mt-2 font-poppins text-12 text-redPink"
                        )}
                      >
                        {contentError ? contentError : ""}
                      </p>
                    </div>
                  </div>
                  <div className={twJoin(activeLang == "sc" ? "sc" : "opacity-0 pointer-events-none max-h-0 overflow-hidden")}>
                    <div className="mb-5 last:mb-0">
                      <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                        Title
                      </label>
                      <input
                        type="text"
                        name="goalTitle_sc"
                        value={goalTitle_sc}
                        onChange={(event) => goalTitleHandler(event, "sc")}
                        placeholder="Title"
                        className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
                      ></input>
                      <p
                        className={classNames(
                          titleError !== "" ? "" : "hidden",
                          "mt-2 font-poppins text-12 text-redPink"
                        )}
                      >
                        {titleError ? titleError : ""}
                      </p>
                    </div>
                    <div className="mb-5 last:mb-0 h-full">
                      <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                        Content
                      </label>
                      <CKEditorContext
                        context={Context}
                        contextWatchdog={ContextWatchdog}
                      >
                        <CKEditor
                          ref={editorRef_sc}
                          editor={ClassicEditor}
                          config={{
                            plugins: [
                              Essentials,
                              Autoformat,
                              Bold,
                              Italic,
                              BlockQuote,
                              CKFinder,
                              CKFinderUploadAdapter,
                              CloudServices,
                              Heading,
                              Image,
                              ImageCaption,
                              ImageStyle,
                              ImageToolbar,
                              ImageUpload,
                              Base64UploadAdapter,
                              Indent,
                              Link,
                              List,
                              Mention,
                              Paragraph,
                              PasteFromOffice,
                              PictureEditing,
                              Table,
                              TableToolbar,
                              TextTransformation,
                            ],
                            toolbar: [
                              "undo",
                              "redo",
                              "|",
                              "heading",
                              "|",
                              "bold",
                              "italic",
                              "|",
                              "link",
                              "uploadImage",
                              "insertTable",
                              "blockQuote",
                              "|",
                              "bulletedList",
                              "numberedList",
                              "|",
                              "outdent",
                              "indent",
                            ],
                          }}
                          data={myGoal_sc ? myGoal_sc : "<p></p>"}
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                          }}
                          onBlur={handleEditorChange}
                        />
                      </CKEditorContext>
                      <p
                        className={classNames(
                          contentError !== "" ? "" : "hidden",
                          "mt-2 font-poppins text-12 text-redPink"
                        )}
                      >
                        {contentError ? contentError : ""}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="md:w-1/2 w-full sm:px-3">
                  <div className="mb-5 last:mb-0">
                    <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                      Publish Date
                    </label>
                    <div className="flex items-center">
                      <div className="w-full max-w-[68%] mr-2">
                        <CustomDatePicker
                          placeholder={new Date().toDateString()}
                          date={publishDate}
                          minData={new Date()}
                          setDate={(value) => {
                            setPublishDate(value);
                          }}
                        />
                      </div>
                      <div className="w-full max-w-[32%] custom_time_picker">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["TimePicker"]}>
                            <DemoItem>
                              <DesktopTimePicker
                                className="py-1"
                                value={dayjs(publishDate)}
                                // defaultValue={publishTime}
                                onChange={(value) => {
                                  console.log(
                                    "format ",
                                    value?.format(),
                                    value ? new Date(value?.format()) : ""
                                  );
                                  setPublishTime(
                                    value
                                      ? new Date(value?.format())
                                      : new Date()
                                  );
                                }}
                              />
                            </DemoItem>
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                    </div>
                    <p
                      className={classNames(
                        expireError !== "" ? "" : "hidden",
                        "mt-2 font-poppins text-12 text-redPink"
                      )}
                    >
                      {expireError ? expireError : ""}
                    </p>
                  </div>
                  <div className="mb-5 last:mb-0">
                    <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                      Expiry Date
                    </label>
                    <div className="flex items-center">
                      <div className="w-full max-w-[68%] mr-2">
                        <CustomDatePicker
                          placeholder={new Date().toDateString()}
                          date={expiryDate}
                          minData={publishDate}
                          setDate={(value) => setExpiryDate(value)}
                        />
                      </div>
                      <div className="w-full max-w-[32%] custom_time_picker">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["TimePicker"]}>
                            <DemoItem>
                              <DesktopTimePicker
                                className="py-1"
                                value={dayjs(expiryDate)}
                                //defaultValue={expireTime}
                                onChange={(value) => {
                                  setExpireTime(
                                    value
                                      ? new Date(value?.format())
                                      : new Date()
                                  );
                                }}
                              />
                            </DemoItem>
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                    </div>

                    <p
                      className={classNames(
                        expireError !== "" ? "" : "hidden",
                        "mt-2 font-poppins text-12 text-redPink"
                      )}
                    >
                      {expireError ? expireError : ""}
                    </p>
                  </div>
                  <div className="mb-5 last:mb-0">
                    <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                      Tag
                    </label>

                    <CommonTagDropdown
                      isCreate={false}
                      isObject={true}
                      createData={createTagData}
                      showAll={true}
                      state={chosenTags}
                      setState={setChosenTags}
                      setSelected={setSelectedCategoryData}
                      options={
                        categories
                          ? categories?.map((workgroup: any) => {
                              return {
                                value: workgroup.id,
                                label: workgroup.name_en,
                              };
                            })
                          : []
                      }
                      className="py-3 border-goalinputborder 6xs:text-goalinputborder text-14 h-[48px]"
                    />
                    <p
                      className={classNames(
                        tagError !== "" ? "" : "hidden",
                        "mt-2 font-poppins text-12 text-redPink"
                      )}
                    >
                      {tagError ? tagError : ""}
                    </p>
                  </div>
                  <div className="mb-5 last:mb-0">
                    <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                      Workgroup
                    </label>

                    <CommonTagDropdown
                      placeholder="Select Workgroup"
                      isCreate={false}
                      showAll={true}
                      isObject={true}
                      state={selectedWorkgroups}
                      setState={setSelectedWorkgroups}
                      setSelected={setSelectedWorkgroupsData}
                      options={
                        workgroups
                          ? workgroups?.map((workgroup: any) => {
                              return {
                                value: workgroup.id,
                                label: workgroup.name_en,
                              };
                            })
                          : []
                      }
                      className="py-3 border-goalinputborder 6xs:text-goalinputborder text-14 h-[48px]"
                    />
                  </div>
                  <div className="mb-5 last:mb-0">
                    <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                      Department
                    </label>

                    <CommonTagDropdown
                      placeholder="Select Department"
                      isCreate={false}
                      createData={createTagData}
                      showAll={true}
                      isObject={true}
                      state={selectedDepartments}
                      setState={setSelectedDepartments}
                      setSelected={setSelectedDepartmentsData}
                      options={
                        departments
                          ? departments?.map((dept: any) => {
                              return {
                                value: dept.id,
                                label: dept.name_en,
                              };
                            })
                          : []
                      }
                      className="py-3 border-goalinputborder 6xs:text-goalinputborder text-14 h-[48px]"
                    />
                    <p
                      className={classNames(
                        departmentError !== "" ? "" : "hidden",
                        "mt-2 font-poppins text-12 text-redPink"
                      )}
                    >
                      {departmentError ? departmentError : ""}
                    </p>
                  </div>
                  <div className="mb-5 last:mb-0">
                    <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                      Employee
                    </label>

                    <CommonTagDropdown
                      placeholder="Select Employee"
                      isCreate={false}
                      createData={createTagData}
                      showAll={true}
                      isObject={true}
                      state={selectedEmployees}
                      setState={setSelectedEmployees}
                      setSelected={setSelectedEmployeesData}
                      options={
                        employees
                          ? employees?.map((emp: any) => {
                              return {
                                value: emp.id,
                                label: emp.name,
                              };
                            })
                          : []
                      }
                      className="py-3 border-goalinputborder 6xs:text-goalinputborder text-14 h-[48px]"
                    />
                    <p
                      className={classNames(
                        employeeError !== "" ? "" : "hidden",
                        "mt-2 font-poppins text-12 text-redPink"
                      )}
                    >
                      {employeeError ? employeeError : ""}
                    </p>
                  </div>
                  <div className="mb-5 last:mb-0">
                    <p className="text-black2 text-12 sm:text-15 font-medium font-primary mb-[0.688rem] block leading-tight">
                      Supportive Documents:
                    </p>
                    <div className=" border border-gray-300 text-gray-400">
                      <p className=" text-sm px-4 pt-3">Upload file (.pdf)</p>
                      <div className="flex items-center p-4">
                        <input
                          type="file"
                          id="upload"
                          name="file"
                          onChange={UploadFile}
                          ref={uploadRef}
                          className="hidden"
                          accept=".pdf"
                        />
                        <button
                          onClick={handleClick}
                          className={classNames(
                            allPrevFiles.length > 5 || uploading
                              ? "pointer-events-none"
                              : ""
                          )}
                        >
                          Choose file
                        </button>
                        <p className="font-primary text-10 sm:text-12 text-black2 ml-3">
                          {selectedFileName}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mb-0 last:mb-0 w-full">
                    <label
                      className="text-black2 font-normal body-15"
                      htmlFor=""
                    >
                      Download Status
                    </label>
                    <div className={`w-fit h-fit ${Styles.customSwitch}`}>
                      <label
                        htmlFor="status"
                        className="flex items-center cursor-pointer relative"
                      >
                        <input
                          type="checkbox"
                          id="status"
                          name="status"
                          value={`${status}`}
                          checked={status}
                          className="sr-only"
                          onChange={(e) => {
                            setStatus(e.target.checked);
                          }}
                        />
                        <div className="toggleBg bg-[#EFF2F5] w-10 h-6 sm:h-[30px] sm:w-12 rounded-full after:absolute after:content-[''] after:top-1 after:left-1 after:bg-white after:rounded-full  after:h-4 after:w-4  after:sm:h-[22px] after:sm:w-[22px] transition-all duration-300 after:transition-all after:duration-300"></div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 last:mb-0 flex items-center sm:px-5">
                <button
                  className={classNames(
                    saving ? "opacity-50 pointer-events-none" : "",
                    "flex items-center justify-center bg-vorpblue border border-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
                  )}
                  onClick={(event) => testingHandler(event, false)}
                >
                  {saving == true ? (
                    <div className="flex items-center justify-center w-full">
                      <CircularProgress
                        sx={{ color: "white" }}
                        className="w-5 h-5"
                        style={{ width: "20px", height: "20px" }}
                      />
                      <span className="ml-2">Saving</span>
                    </div>
                  ) : (
                    <div className="flex items-center">
                      <img
                        src={saveIcon}
                        alt="save icon"
                        className="mr-3"
                      ></img>
                      {editRowData?.id ? "Update" : "Save"}
                    </div>
                  )}
                </button>
                <button
                  className=" mr-2.5 flex items-center justify-center bg-offwhite border border-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
                  onClick={() => {
                    setIsOpen(false);
                    clearData();
                  }}
                >
                  <img
                    src={blackCancel}
                    alt="black icon"
                    className="mr-3"
                  ></img>
                  Cancel
                </button>
                <button
                  className="flex items-center justify-center bg-white border border-vorpblue mr-2.5 text-vorpblue font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
                  onClick={(e) => {
                    testingHandler(e, true);
                  }}
                >
                  <img
                    style={{
                      filter:
                        "brightness(0) saturate(100%) invert(24%) sepia(65%) saturate(1205%) hue-rotate(319deg) brightness(94%) contrast(96%)",
                    }}
                    src={saveIcon}
                    alt="black icon"
                    className="mr-3"
                  ></img>
                  Save as Draft
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default NoticeBoardModalEditor;
