import {
    Dispatch,
    FC,
    SetStateAction,
    // useCallback,
    useEffect,
    // useMemo,
    useRef,
    useState,
} from "react";
import {
    DropdownICon,
    SearchIcon,
} from "../common/Icons";
import DebounceSearchInput from "../Tables/DebounceSearchInput";
import classNames from "classnames";
import styles from "./EmployeeTable.module.scss";
import { Box, Fade, Popper, ClickAwayListener } from "@mui/material";
import CustomScrollbar from "../CustomScrollbar/CustomScrollbar";
import { assignedColor } from "../Documents/EmployeeDocumentsTable";
interface ICustomDropdownList {
    state: any;
    setState: Dispatch<SetStateAction<any>> | (() => void);
    placeholder?: string;
    dropdownItems?: IListItem[];
    // dropdownItems?: EmployeeData[];
    className?: string;
    textAlign?: string;
    dropdownborder?: string;
    isFilter?: boolean;
    classNameCus?: string;
    isAction?: boolean;
    customClassName?: string;
    isObjectGet?: boolean;
}
interface IListItem {
    label: string;
    value: string | number | undefined;
}
const CustomDropdownListForFilterWithImage: FC<ICustomDropdownList> = ({
    state,
    setState,
    dropdownItems,
    placeholder = "All",
    classNameCus,
    isAction,
    customClassName,
    isObjectGet,
}) => {
    const [placement, setPlacement] = useState<any>("bottom-start");
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState(false);
    const [label, setLabel] = useState<string>(
        dropdownItems?.filter((item) => item.value?.toString() == state)[0]
            ?.label || placeholder
    );

    // const uuid = uniqueId("custom-dropdown-list");
    const [search, SetSearch] = useState<string>("");
    const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);
    const [isCreated, setIsCreated] = useState<boolean>(false);
    const mainContainer = useRef<HTMLDivElement | null>(null);

    const [width, setWidth] = useState<number>();

    const [filteredList, setFilteredList] = useState<any>([]);

    useEffect(() => {
        const keyword = search?.trim().toLowerCase();
        if (keyword.length > 0) {
            const matchedItems = dropdownItems?.filter((item) =>
                item.label?.toLowerCase().includes(keyword)
            );
            setFilteredList(matchedItems);
        } else {
            setFilteredList(dropdownItems);
        }
    }, [search, setFilteredList, dropdownItems]);

    useEffect(() => {
        setWidth(mainContainer.current?.clientWidth as number);
        const resizer: any = window.addEventListener("resize", () =>
            setWidth(mainContainer.current?.clientWidth as number)
        );
        return () => window.removeEventListener("resize", resizer);
    }, []);

    const handleClick = (
        newPlacement: any,
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setAnchorEl(event.currentTarget);
        setPlacement(newPlacement);
    };

    const handleClickAway = () => {
        if (showCreatePopup == true || isCreated == true) {
            setOpen(true);
            setShow(true);
            setIsCreated(false);
        } else {
            setOpen(false);
            setShow(false);
            setIsCreated(false);
        }
    };

    return (
        <div ref={mainContainer} className="h-full w-full">
            <ClickAwayListener onClickAway={handleClickAway}>
                <Box
                    sx={{ position: "relative" }}
                    className="h-full w-full transition-all duration-500"
                >
                    <button
                        onClick={(event) => {
                            handleClick("bottom-start", event);
                            setOpen(true);
                            setShow(true);
                        }}
                        className={classNames(
                            open ? "bg-[#EFEFEF]" : "bg-white",
                            customClassName,
                            "hover:text-vorpblue dropdown-hover-btn rounded-[28px] flex w-full items-center justify-between h-full transition-all duration-300 py-[2px] px-[10px] custom-dropdown-container"
                        )}
                    >
                        <p className="whitespace-nowrap xl:text-[13px] text-12 text-black2 font-semibold mr-1">
                            {isAction
                                ? state
                                : label
                                    ? placeholder != label && placeholder != "All"
                                        ? placeholder + ": " + label
                                        : placeholder
                                    : "All"}
                        </p>
                        <DropdownICon />
                    </button>
                    {show ? (
                        <Popper
                            open={open}
                            anchorEl={anchorEl}
                            placement={placement}
                            transition
                        >
                            {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={500}>
                                    <div>
                                        <div
                                            style={{ width }}
                                            className={classNames(
                                                styles.customList,
                                                "rounded-4 overflow-hidden w-full max-w-[185px] z-10 bg-white",
                                                classNameCus
                                            )}
                                        >
                                            <div className="bg-offwhite py-2 px-3">
                                                <div className="relative">
                                                    <DebounceSearchInput
                                                        setState={SetSearch}
                                                        debounce={400}
                                                    >
                                                        {(setState) => (
                                                            <input
                                                                type="text"
                                                                placeholder="Search"
                                                                className="py-1 pl-3 pr-2 placeholder:text-black2 text-black2 border border-vorpgraylight rounded-4 focus:outline-none w-full xl:text-sm text-12 leading-5"
                                                                onChange={(e) => setState(e.target.value)}
                                                                onKeyDown={(e) => e.stopPropagation()}
                                                            />
                                                        )}
                                                    </DebounceSearchInput>
                                                    <SearchIcon className="absolute top-0 bottom-0 my-auto right-2" />
                                                </div>
                                            </div>
                                            <div className="flex flex-col overflow-y-auto w-full h-[218px] max-h-[218px] bg-white">
                                                <CustomScrollbar
                                                    color="#CACACA"
                                                    isVertical={true}
                                                    isHor={false}
                                                >
                                                    <div className="">
                                                        {filteredList?.map((item: any, index: number) => {
                                                            let idx = index > 4 ? index % 4 : index;
                                                            return <div
                                                                key={Math.random() * 1000 * index}
                                                                className={classNames(
                                                                    item.id == 0 ? "hidden" : "flex",
                                                                    " flex-row items-center justify-between hover:bg-vorpmyanmar text-graydark"
                                                                )}
                                                            >
                                                                <button
                                                                    type="button"
                                                                    onClick={() => {
                                                                        if (isObjectGet) {
                                                                            setState(item);
                                                                            setLabel(item?.label);
                                                                            setOpen(false);
                                                                        } else {
                                                                            if (isAction) {
                                                                                setState(item?.label);
                                                                                setOpen(false);
                                                                            } else {
                                                                                setState(item?.value as any);
                                                                                setLabel(item?.label);
                                                                                setOpen(false);
                                                                            }
                                                                        }
                                                                    }}
                                                                    className={classNames(
                                                                        label == item.label ? "bg-vorpmyanmar" : "",
                                                                        "whitespace-normal break-words w-full capitalize focus:outline-none flex items-center justify-start group px-3 py-1  transition-all duration-300 text-left xl:text-sm text-12 leading-5 words-break"
                                                                    )}
                                                                >
                                                                    {item.photo ? <div><img src={item.photo} alt="profile" className="max-w-[40px] max-h-[40px] object-cover"/></div> :
                                                                        <div className={classNames("w-10 h-10 rounded-full mr-2")} style={{ backgroundColor: assignedColor[idx] }}>
                                                                            <p className="flex h-full justify-center items-center text-white font-bold">{item.label.substring(0, 1)}</p>
                                                                        </div>}
                                                                    {item.label}
                                                                </button>
                                                            </div>
                                                        })}
                                                    </div>
                                                </CustomScrollbar>
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            )}
                        </Popper>
                    ) : null}
                </Box>
            </ClickAwayListener>
        </div>
    );
};

export default CustomDropdownListForFilterWithImage;
