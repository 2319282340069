import { redirectToLogin } from "./annoucement-sheet";
import { api, language } from "./api";
import authHeader from "./auth-header";
export type DropdownDataWithLanguage = {
  id: number;
  name_en: string;
  name_tc: string;
  name_sc: string;
};
type DepartmentsResponse = {
  module_id: number;
  status: boolean;
  typeTableData: TData;
};

export type TData = {
  data: DepartmentInfo[];
  total: number;
};
export type DepartmentInfo = {
  id?: number;
  name: string;
};
type departmentInput = {
  page: number;
  limit: number | string | undefined,
  search: string | number,
};
export type EventTypeList = {
  typeList: TaskDepartmentListData[];
};
export type TaskDepartmentListData = {
  id: number;
  name: string;
};

export const annoucementTypeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAnnoucemmentTypesData: build.query<DepartmentsResponse, departmentInput>({
      query: ({page,limit,search}) => ({
        url: `announcementtypes?page=${page}&limit=${limit}&search=${search}`,
        headers: authHeader(),
      }),
      keepUnusedDataFor:0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    getAnnoucemmentTypeslList: build.query<EventTypeList, void>({
      query: () => ({
        url: `getAnnouncementTypeList`,
        headers: authHeader(),
      }),
      keepUnusedDataFor:0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    createAnnoucemmentType: build.mutation<
    EventTypeList,
      DropdownDataWithLanguage
    >({
      query(body) {
        return {
          headers: authHeader(),
          url: `announcementtypes`,
          body,
          method: "POST",
        };
      },
    }),
    updateAnnoucemmentType: build.mutation<
    EventTypeList,
      DropdownDataWithLanguage
    >({
      query(body) {
        return {
          headers: authHeader(),
          url: `announcementtypes/${body.id}`,
          body,
          method: "PUT",
        };
      },
      // invalidatesTags: (employees) => {
      //   return [{ type: "Departments", id: "LIST" }];
      // },
    }),
    deleteAnnoucemmentType: build.mutation<EventTypeList, number>({
      query(id) {
        return {
          headers: authHeader(),
          url: `/announcementtypes/${id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
 useGetAnnoucemmentTypesDataQuery,
 useGetAnnoucemmentTypeslListQuery,
 useCreateAnnoucemmentTypeMutation,
 useUpdateAnnoucemmentTypeMutation,
 useDeleteAnnoucemmentTypeMutation
} = annoucementTypeApi;

export const {
  endpoints: {
    getAnnoucemmentTypesData,
    getAnnoucemmentTypeslList,
    createAnnoucemmentType,
    updateAnnoucemmentType,
    deleteAnnoucemmentType
  },
} = annoucementTypeApi;
