import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { baseObj } from "../../utils/constants";
import { Cell } from "@tanstack/react-table";
// import { makeStyles, Tooltip } from "@material-ui/core";
import { makeStyles } from "@mui/styles";
import { Tooltip } from "@mui/material";

import CopyToClipboardBtn from "../DashboardProfile/MyPasswords/CopyToClipboardBtn";
import eyehide from "../../login/img/eye-open.svg";
import eyeshow from "../../login/img/eye-show.svg";
import classNames from "classnames";

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    margin: 0,
    marginRight: 8,
    padding: 0,
  },
}));
interface IEditableCell {
  value: string | number;
  cell: Cell<any, any>;
  type: string;
  setData: Dispatch<SetStateAction<baseObj[]>>;
  className?: string;
  CustomComponent?: ReactNode;
  hasPadding?: boolean;
  formatResult?: (value: string | number | string[]) => ReactNode;
  customDesign?: string;
  customDesignOverflow?: string;
  IsDisabled?: boolean;
  isDuration?: boolean;
  setEditProjectAmountValue?: Dispatch<any>;
  maxLength?: number;
  setIsChanged?: any;
  isChanged?: string;
  isYear?: boolean;
  durationType?: any;
}

const EditableCell: FC<IEditableCell> = ({
  value,
  cell,
  type,
  setData,
  className,
  CustomComponent,
  hasPadding = false,
  formatResult,
  customDesign,
  IsDisabled,
  isDuration,
  durationType,
  setEditProjectAmountValue,
  maxLength,
  setIsChanged,
  isChanged,
  isYear,
}) => {
  const [fieldValue, setFieldValue] = useState<string | number | string[]>(
    value || ""
  );
  const classes = useStyles();
  // const isEllipsisActive = (element: any) => {
  //   return element.offsetWidth < element.scrollWidth;
  // }

  const isEditable = cell.row.original?.isNew;
  const fieldType = type === "email" ? "text" : type;
  const editCell = useCallback(() => {
    setData((prev: baseObj[]) => {
      return prev.map((data: baseObj, index: number) => {
        if (index === Number(cell.row.id)) {
          if (cell.column.id == "password") {
            return { ...data, [cell.column.id]: fieldValue, isChanged: true };
          } else {
            return { ...data, [cell.column.id]: fieldValue, isChanged: false };
          }
        }
        return data;
      });
    });
  }, [fieldValue, setData, cell]);

  useEffect(() => {
    if (setEditProjectAmountValue) {
      setEditProjectAmountValue(fieldValue);
    }
  }, [fieldValue]);
  const switchEyeHandler = (event: any) => {
    var hideimg = eyehide;
    var showimg = eyeshow;
    const type =
      event.target.previousElementSibling.getAttribute("type") === "password"
        ? "text"
        : "password";
    const img =
      event.target.previousElementSibling.getAttribute("type") === "password"
        ? hideimg
        : showimg;
    event.target.previousElementSibling.setAttribute("type", type);
    event.target.setAttribute("src", img);
  };
  if (!isEditable) {
    if (customDesign == "circle" && fieldValue != undefined) {
      return (
        <div className="flex items-center h-full">
          <div className=" w-[30px] h-[30px] bg-[#FFC700] rounded-full relative">
            <p className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center text-13 font-bold text-white text-center">
              {fieldValue != undefined
                ? fieldValue?.toString()?.slice(0, 1)
                : ""}
            </p>
          </div>
          <p className="ml-2 text-14 text-[#464E5F] font-normal">
            {fieldValue != undefined ? fieldValue?.toString() : "---"}
          </p>
        </div>
      );
    } else if (customDesign == "tooltip" && fieldValue != undefined) {
      return (
        <div className="w-full ">
          <p className="w-[97%] tracking-normal font-poppins lg:text-sm text-12 font-normal text-[#464E5F] text-left px-2 overflow-hidden whitespace-nowrap">
            {fieldValue && fieldValue != "Select" ? fieldValue : "---"}
          </p>
          {(maxLength && fieldValue.toString().length>0
            ? (fieldValue.toString().length > 0&&fieldValue.toString().length > maxLength)
            : (fieldValue.toString().length > 18 && fieldValue.toString().length > 0)) && (
            <>
              <Tooltip
                arrow
                classes={{ tooltip: classes.tooltip }}
                enterTouchDelay={0}
                leaveTouchDelay={5000}
                placement="left"
                title={
                  <>
                    <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full bg-tooltipbg">
                      <span className="block">{fieldValue}</span>
                    </div>
                  </>
                }
              >
                <span className="font-semibold cursor-pointer">+</span>
              </Tooltip>
              <div className="relative hover:cursor-pointer pl-1"></div>
            </>
          )}
        </div>
      );
    } else if (customDesign == "link" && fieldValue != undefined) {
      var link = fieldValue.toString();
      return (
        <div className="min-w-full max-w-[200px] flex items-center">
          {/* <Tooltip
            arrow
            classes={{ tooltip: classes.tooltip }}
            enterTouchDelay={0}
            leaveTouchDelay={5000}
            placement="left"
            title={
              <>
                <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full bg-tooltipbg">
                  <a
                    className="text-white underline"
                    target="_blank"
                    href={link}
                  >
                    {link}
                  </a>
                </div>
              </>
            }
          >
            <div className="flex justify-between w-full">
              <p className="w-[85%] overflow-hidden text-black2 font-normal lg:text-14 text-12">
                <a
                  className="text-vorpblue underline"
                  target="_blank"
                  href={link}
                >
                  {link ?? "---"}
                </a>
              </p>
              {link && (
                <div>
                  <CopyToClipboardBtn value={link} isAccount={true} />
                </div>
              )}
            </div>
          </Tooltip> */}
          <div className="flex justify-between w-full">
            <p className="w-[85%] overflow-hidden text-black2 font-normal lg:text-14 text-12">
              <a
                className="text-vorpblue underline"
                target="_blank"
                href={link}
              >
                {link ?? "---"}
              </a>
            </p>
            {link && (
              <div>
                <CopyToClipboardBtn value={link} isAccount={true} />
              </div>
            )}
          </div>
        </div>
      );
    } else if (customDesign == "text" && fieldValue != undefined) {
      return (
        <div className="w-[90%] ">
          <p className="w-full tracking-normal font-poppins lg:text-sm text-12 font-normal text-[#464E5F] text-left px-2 overflow-hidden whitespace-nowrap">
            {fieldValue && fieldValue != "Select" ? fieldValue : "---"}
          </p>
        </div>
      );
    }
    return (
      <div
        className={`${className} ${
          hasPadding
            ? "py-0 px-[10px] flex items-center"
            : "h-full px-2 overflow-hidden whitespace-nowrap text-ellipsis w-[95%]"
        }`}
      >
        {formatResult
          ? formatResult(fieldValue)
          : isYear == true
          ? fieldValue
            ? fieldValue
            : 0
          : isDuration
          ? fieldValue
            ? `${fieldValue} ${'days'}`
            : "---"
          : fieldValue && fieldValue != "Select"
          ? fieldValue
          : "---"}
      </div>
    );
  }

  switch (fieldType) {
    case "text":
      return (
        <div className="min-w-full max-w-[200px] flex items-center">
          <input
            disabled={IsDisabled}
            type={type}
            name={cell.id}
            id={cell.id}
            autoComplete="new-password"
            className={`min-w-full min-h-full w-[80%]  focus:outline-none text-ellipsis overflow-hidden disabled:bg-transparent px-2 font-normal font-poppins ${
              isEditable ? "bg-vorpmyanmar text-black2" : "pointer-events-none"
            } ${className}`}
            value={fieldValue}
            {...(isEditable ? {} : { disabled: true })}
            onChange={(e) => setFieldValue(e.target.value)}
            onBlur={editCell}
          />
        </div>
      );
    case "number":
      return (
        <input
          disabled={IsDisabled}
          autoComplete="new-password"
          type={type}
          name={cell.id}
          id={cell.id}
          className={`min-w-full min-h-full focus:outline-none disabled:bg-transparent px-2 ${
            isEditable ? "bg-vorpmyanmar text-black2" : "pointer-events-none"
          } ${className}`}
          value={fieldValue}
          {...(isEditable ? {} : { disabled: true })}
          onChange={(e) => setFieldValue(e.target.value)}
          onBlur={editCell}
          // defaultValue={value}
        />
      );
    case "password":
      return (
        <div className="flex justify-center min-w-[200px] items-center">
          <input
            disabled={IsDisabled}
            autoComplete="new-password"
            type={type}
            name={cell.id}
            id={cell.id}
            className={`min-w-[200px] min-h-full focus:outline-none disabled:bg-transparent pr-2 font-normal font-poppins ${
              isEditable ? "bg-vorpmyanmar text-black2" : "pointer-events-none"
            } ${className}`}
            value={fieldValue}
            // value={fieldValue == "***********" ? "" : fieldValue}
            {...(isEditable ? {} : { disabled: true })}
            onChange={(e) => setFieldValue(e.target.value)}
            onBlur={editCell}
            // defaultValue={value}
          />
          <img
            src={eyeshow}
            alt="eye-open"
            className={classNames(
              //styles.theeye,
              "absolute top-1/2 -translate-y-1/2 right-3 cursor-pointer w-[22px] h-[16px] object-cover"
            )}
            onClick={switchEyeHandler}
          />
        </div>
      );
    case "custom":
      return <div className="w-full h-full">{CustomComponent}</div>;
    case "label":
      return <div className="w-full h-full">{fieldValue}</div>;
    default:
      <></>;
  }
};

export default EditableCell;
